<template>
  <div id="address-information">
    <v-card id="v-card" v-if="StartupLoadingFlag">
      <v-container>
        <v-row wrap>
          <v-col cols="12" xl="6" md="6" sm="6" v-for="n in 4" :key="n">
            <br />
            <br />
            <v-skeleton-loader
              v-bind="attrs"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      elevation="4"
      outlined
      shaped
      v-if="!StartupLoadingFlag"
      id="v-card"
    >
      <br />
      <v-row wrap>
        <v-col cols="9" lg="9">
          <h3 class="text-dark lg-ml-3 ml-3">
            <i id="app-bar-icon" class="fas fa-users"></i>&nbsp;&nbsp;
            <b id="heading">Training Career</b>
          </h3>
        </v-col>
        <v-col cols="2" lg="2">
          <p
            class="btn btn-sm font-weight-bolder text-uppercase"
            id="add-button"
            v-if="tableOptions1.CreateButtonFlag"
            @click.prevent="addRecordPrompt = true"
          >
            Add
          </p>
        </v-col>
      </v-row>

      <v-card-text style="padding-left: 1%; padding-right: 0%">
        <!-- <v-row wrap>
          <v-col align="center" cols="12" xl="12" lg="12" md="12" sm="12">
            <v-btn
              v-if="tableOptions1.RefreshButtonFlag"
              @click.prevent="refreshPageData"
              :loading="SearchFlag"
              small
              target="_blank"
              color="#4387f4"
              class="btn btn-primary font-size-h6 px-5 py-4 my-3 mr-3 white--text"
            >
              Refresh
            </v-btn>
          </v-col>
        </v-row> -->

        <v-container class="py-0" v-if="tableData1.length > 0">
          <!-- <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h5 class="text-primary">
                {{ tableData1.length }} records found.
              </h5>
            </v-col>
          </v-row> -->
          <v-row wrap id="card-text">
            <v-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              v-for="item in tableData1"
              :key="item.Id"
            >
              <v-row wrap>
                <v-col cols="10" lg="10">
                  <h5>
                    {{ item.Id }}. &nbsp;
                    <span class="text-dark">
                      <!-- <v-chip
                        :color="getActiveStatusColor(item.ActiveStatusTxt)"
                        label
                        text-color="white"
                      >
                        <v-icon start icon="mdi-label"></v-icon>
                        {{ item.ActiveStatusTxt }}
                      </v-chip> -->
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            :color="getActiveStatusColor(item.ActiveStatusTxt)"
                          >
                            mdi-information
                          </v-icon>
                        </template>
                        <span>{{ item.ActiveStatusTxt }}</span>
                      </v-tooltip>
                      &nbsp; - &nbsp; {{ item.TrainerTypeName }}
                      <!-- &nbsp; - &nbsp; {{ item.DateTxt }} -->
                    </span>
                  </h5>
                </v-col>
                <v-col cols="2" lg="2">
                  <span>
                    <!-- <v-icon
                      medium
                      color="red"
                      @click="deleteDataAlert(item, e)"
                      v-if="tableOptions1.DeleteButtonFlag"
                      >mdi-delete
                    </v-icon> -->
                    <v-tooltip bottom v-if="tableOptions1.DeleteButtonFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          medium
                          color="red"
                          @click="deleteDataAlert(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span> Delete </span>
                    </v-tooltip>
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- <v-data-iterator
            :items="tableData1"
            :items-per-page="-1"
            hide-default-footer
          >
            <template v-slot:header> </template>

            <template v-slot:default="props">
              <v-row>
                <v-col
                  cols="12"
                  xl="4"
                  md="6"
                  sm="6"
                  v-for="item in props.items"
                  :key="item.Id"
                >
                  <v-card raised hover elevation="25">
                    <v-card-title>
                      <v-app-bar
                        height="35%"
                        :color="getActiveStatusColor(item.ActiveStatusTxt)"
                        dense
                        dark
                        elevate-on-scroll
                        scroll-target="#scrolling-techniques-7"
                      >
                        <v-toolbar-title>
                          <span style="font-size: medium">
                            {{ item.ActiveStatusTxt }}
                          </span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-icon
                          elevation="30"
                          shaped
                          tile
                          medium
                          color="white"
                          @click="deleteDataAlert(item, e)"
                          v-if="tableOptions1.DeleteButtonFlag"
                          >mdi-delete</v-icon
                        >
                      </v-app-bar>
                    </v-card-title>
                    <v-card-text>
                      <v-row wrap class="text-left">
                        <v-col sm="4" md="4" lg="6" xl="6">
                          <h6 align="center">Certificate</h6>
                          <viewer rebuild :images="item.AllImages">
                            <img
                              style="padding: 5px"
                              width="100"
                              height="100"
                              :src="src"
                              :key="src"
                              v-for="src in item.AllImages"
                            />
                          </viewer>
                        </v-col>
                        <v-col sm="8" md="8" lg="6" xl="12">
                          <h6>
                            Trainer Type
                            <span class="text-dark">
                              {{ item.TrainerTypeName }}
                            </span>
                          </h6>
                          <br />
                          <h6>
                            Date
                            <span class="text-dark">
                              {{ item.DateTxt }}
                            </span>
                          </h6>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator> -->
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="addRecordPrompt" persistent max-width="75%">
      <add-training-career-details
        pageTitle="Add Training Career Details"
        pageDescription="Add Training Career Details"
        :addRecordPrompt="addRecordPrompt"
        @hideAddRecordPrompt="changeAddRecordPrompt"
        v-if="addRecordPrompt"
      ></add-training-career-details>
    </v-dialog>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import Swal from "sweetalert2";

import AddTrainingCareerDetails from "@/view/pages/profile/my-profile/AddTrainingCareerDetails";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {
    AddTrainingCareerDetails,
  },

  data() {
    return {
      StartupLoadingFlag: false,
      MemberId: 0,
      rows: {},

      pageTitle: "Training Career",
      pageDescription: "List of training career details",

      DeleteLoadingFlag: false,

      SearchFlag: false,
      ResultFlag: false,

      selected: [],
      selectedData: {},
      search: "",

      FloatingButton: {},

      tableColumns1: [],
      tableOptions1: [],
      tableData1: [],

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    changeAddRecordPrompt(flag) {
      console.log("changeAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.getTableRecords();
      }
    },
    getTableRecords() {
      console.log("getTableRecords is called");

      // var validate1 = this.$refs.form2.validate();
      var validate1 = true;
      console.log("validate1=" + validate1);

      if (validate1) {
        this.StartupLoadingFlag = true;
        this.SearchFlag = true;
        this.ResultFlag = false;
        this.tableData1 = [];
        this.selected = [];
        this.selectedData = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/trainer-details/lists";
        var upload = {
          UserInterface: 1,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.StartupLoadingFlag = false;
            thisIns.SearchFlag = false;
            thisIns.ResultFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            thisIns.tableOptions1 = records.TableOptions;
            thisIns.tableColumns1 = records.TableHeader;
            thisIns.tableData1 = records.TableData;
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
            thisIns.StartupLoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message, false);
      }
    },
    deleteDataAlert(tr, event) {
      console.log("deleteDataAlert called");
      // event.prevent.default();
      // this.selected = tr;
      // var n1 = tr.length;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.confirmAlert();
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      //   var validate1 = this.$refs.form1.validate();
      //   console.log("validate1=" + validate1);
      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "Trainer Type <b> " + tr.TrainerTypeName + "</b>";
        htmlTxt += "<br/> Status <b> " + tr.ActiveStatusTxt + "</b>";
        htmlTxt += "<br/> Date <b> " + tr.DateTxt + "</b>";
        htmlTxt +=
          "<br/><img width='100' height='100' src='" + tr.ImagePath + "' />";
        htmlTxt += "<br/><br/>Do you want to delete the data?";
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete the data?",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    deleteData() {
      console.log("deleteData is called");

      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.DeleteLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/trainer-details/destroy";
        var upload = {
          UserInterface: 1,
          TrainerId: tr.TrainerId,
          TrainerType: tr.TrainerTypeId,
          Member: tr.MemberId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            // thisIns.SearchFlag = false;
            // thisIns.ResultFlag = true;
            thisIns.DeleteLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.selectedData = {};
              thisIns.selected = [];
              thisIns.getTableRecords();
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.DeleteLoadingFlag = false;
          });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt");
    },
  },
  mounted() {},
  beforeMount() {
    console.log("beforeMount called");
    this.refreshPageData();
  },
};
</script>
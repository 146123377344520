<template>
  <div id="address-information">
    <v-card id="v-card" v-if="StartupLoadingFlag">
      <v-container>
        <v-row wrap>
          <v-col cols="12" xl="6" md="6" sm="6" v-for="n in 4" :key="n">
            <br />
            <br />
            <v-skeleton-loader
              v-bind="attrs"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      elevation="4"
      outlined
      shaped
      v-if="!StartupLoadingFlag"
      id="v-card"
    >
      <br />

      <v-row wrap>
        <v-col cols="9" lg="9">
          <h3 class="text-dark lg-ml-3 ml-3">
            <i id="app-bar-icon" class="fas fa-id-card-alt"></i>&nbsp;&nbsp;
            <b id="heading">Document Details</b>
          </h3>
        </v-col>
        <v-col cols="2" lg="2">
          <p
            class="btn btn-sm font-weight-bolder text-uppercase"
            id="add-button"
            v-if="tableOptions1.CreateButtonFlag"
            @click.prevent="addRecordPrompt = true"
          >
            Add
          </p>
        </v-col>
      </v-row>

      <v-card-text style="padding-left: 1%; padding-right: 0%" id="card-text">
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap>
            <v-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              v-for="(item, index) in tableData1"
              :key="index"
            >
              <v-row>
                <v-col cols="10" lg="10">
                  <h5>
                    {{ index + 1 }}.
                    <span class="text-dark">
                      {{ item.DocumentName }}
                      <!-- {{ item.ReferenceNo }} -->
                    </span>
                    &nbsp; - &nbsp;
                    <img
                      style="padding: 5px"
                      width="70"
                      height="70"
                      :src="item.ImagePath"
                      :alt="item.DocumentName"
                    />
                    <!-- <viewer rebuild :images="item.AllImages">
                      <img
                        style="padding: 5px"
                        width="60"
                        height="60"
                        :src="src"
                        :key="src"
                        :alt="item.DocumentName"
                        v-for="src in item.AllImages"
                      />
                    </viewer> -->
                  </h5>
                </v-col>
                <v-col cols="2" lg="2">
                  <!-- <v-icon
                    medium
                    color="red"
                    @click="deleteDataAlert(item, e)"
                    v-if="tableOptions1.DeleteButtonFlag"
                    >mdi-delete
                  </v-icon> -->
                  <v-tooltip bottom v-if="tableOptions1.DeleteButtonFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        medium
                        color="red"
                        @click="deleteDataAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span> Delete </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="addRecordPrompt" persistent width="75%">
      <add-new-document
        pageDescription="Add New Document"
        :addRecordPrompt="addRecordPrompt"
        @hideAddRecordPrompt="changeAddRecordPrompt"
        v-if="addRecordPrompt"
      ></add-new-document>
    </v-dialog>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import Swal from "sweetalert2";

import AddNewDocument from "@/view/pages/profile/my-profile/AddNewDocument";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {
    AddNewDocument,
  },

  data() {
    return {
      StartupLoadingFlag: false,
      MemberId: 0,

      pageTitle: "Document details",
      pageDescription: "List of document details for age proof",

      DeleteLoadingFlag: false,

      SearchFlag: false,
      ResultFlag: false,

      selected: [],
      selectedData: {},
      search: "",

      FloatingButton: {},

      tableColumns1: [],
      tableOptions1: {
        FloatingButton: {},
      },
      tableData1: [],

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    changeAddRecordPrompt(flag) {
      console.log("changeAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log(flag);
      if (flag) {
        this.getTableRecords();
      }
    },
    getTableRecords() {
      console.log("getTableRecords is called");

      // var validate1 = this.$refs.form2.validate();
      var validate1 = true;
      console.log("validate1=" + validate1);

      if (validate1) {
        this.SearchFlag = true;
        this.StartupLoadingFlag = true;
        this.ResultFlag = false;
        this.tableData1 = [];
        this.selected = [];
        this.selectedData = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members-document-details/show";
        var upload = {
          UserInterface: 1,
          MemberId: this.MemberId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;
            thisIns.StartupLoadingFlag = false;
            thisIns.ResultFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.tableOptions1 = records.TableOptions;
            thisIns.tableColumns1 = records.TableHeader;

            if (flag == 1) {
              // thisIns.toast("success", output);
              thisIns.tableData1 = records.TableData;
              console.log(thisIns.tableData1);
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
            thisIns.StartupLoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message, false);
      }
    },
    deleteDataAlert(tr, event) {
      console.log("deleteDataAlert called");
      // event.prevent.default();
      // this.selected = tr;
      // var n1 = tr.length;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.confirmAlert();
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      //   var validate1 = this.$refs.form1.validate();
      //   console.log("validate1=" + validate1);
      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "Document name <b> " + tr.DocumentName + "</b>";
        htmlTxt += "<br/> Reference No <b> " + tr.ReferenceNo + "</b>";
        htmlTxt +=
          "<br/><img width='100' height='100' src='" + tr.ImagePath + "' />";
        htmlTxt += "<br/><br/>Do you want to delete the data?";
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete the data?",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    deleteData() {
      console.log("deleteData is called");

      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.DeleteLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members-document-details/destroy";
        var upload = {
          UserInterface: 1,
          Member: tr.MemberId,
          Document: tr.DocumentId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            // thisIns.SearchFlag = false;
            // thisIns.ResultFlag = true;
            thisIns.DeleteLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.selectedData = {};
              thisIns.selected = [];
              thisIns.getTableRecords();
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.DeleteLoadingFlag = false;
          });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt");
    },
  },
  mounted() {},
  beforeMount() {
    console.log("beforeMount called");
    console.log(this.$route.query.id);
    // this.MemberId = this.$route.query.id;
    var MemberId = this.$session.get("MemberId");
    MemberId = MemberId == (null || undefined) ? 0 : MemberId;
    console.log("my-profile beforeMount called");

    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    if (token == 0) {
      this.$router.push("/login");
    } else {
      this.UserFlag = true;
      this.MemberId = MemberId;
      this.refreshPageData();
    }
  },
};
</script>
<template>
  <div id="profile-page4">
    <v-row wrap>
      <v-col cols="12" lg="12" md="12" sm="12">
        <personal-information />
      </v-col>
      <v-col cols="12" lg="4">
        <communication-details />
      </v-col>
      <v-col cols="12" lg="4">
        <address-information />
      </v-col>
      <v-col cols="12" lg="4">
        <nominee-details />
      </v-col>
      <v-col cols="12" lg="4">
        <training-career />
      </v-col>
      <v-col cols="12" lg="4">
        <profession-details />
      </v-col>
      <v-col cols="12" lg="4">
        <document-details />
      </v-col>
      <v-col cols="12" lg="4">
        <view-details />
      </v-col>
      <v-col cols="12" lg="4">
        <social-media />
      </v-col>
      <v-col cols="12" lg="4">
        <foundation-contribution />
      </v-col>
      <v-col cols="12" lg="4">
        <family-details />
      </v-col>
      <v-col cols="12" lg="4">
        <blood-donation-details />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PersonalInformation from "@/view/pages/profile/profile-cards-1/PersonalInformation";
import TrainingCareer from "@/view/pages/profile/profile-cards-1/TrainingCareer";
import DocumentDetails from "@/view/pages/profile/profile-cards-1/DocumentDetails";
import ProfessionDetails from "@/view/pages/profile/profile-cards-1/ProfessionDetails";
import BloodDonationDetails from "@/view/pages/profile/profile-cards-1/BloodDonationDetails";
import FoundationContribution from "@/view/pages/profile/profile-cards-1/FoundationContribution";
import ViewDetails from "@/view/pages/profile/profile-cards-1/ViewDetails";
import NomineeDetails from "@/view/pages/profile/profile-cards-1/NomineeDetails";
import SocialMedia from "@/view/pages/profile/profile-cards-1/SocialMedia";
import CommunicationDetails from "@/view/pages/profile/profile-cards-1/CommunicationDetails";
import AddressInformation from "@/view/pages/profile/profile-cards-1/AddressInformation";
import FamilyDetails from "@/view/pages/profile/profile-cards-1/FamilyDetails";

export default {
  name: "custom-page",
  components: {
    PersonalInformation,
    TrainingCareer,
    DocumentDetails,
    ProfessionDetails,
    BloodDonationDetails,
    FoundationContribution,
    ViewDetails,
    NomineeDetails,
    SocialMedia,
    CommunicationDetails,
    AddressInformation,
    FamilyDetails,
  },
  computed: {
    ...mapGetters(["currentUserPhoto"]),
  },
  data() {
    return {
      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      totalActiveAwards: 0,
      totalPendingAwards: 0,

      ResultFlag: 0,

      tab: null,
      MemberId: "",
      address: {},
      contact: [],
      designation: [],
      dues: [],

      rows: [],
      RoleId: "",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile 3" }]);
  },
  watch: {
    rows: function () {
      console.log("watch rows");
      var rows = this.rows;
      var n1 = Object.keys(rows).length;
      console.log("n1=" + n1 + ", rows=" + JSON.stringify(rows));
      if (n1 > 0) {
        this.ResultFlag = 1;
        this.address = rows.Address[0];
        this.contact = rows.Contact;
        this.designation = rows.OfficesHeld;
        this.dues = rows.Dues;
      }
    },
  },
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    resetMessageTxt() {
      console.log("resetMessageTxt called");
      this.ErrorMessageTxt = 0;
      this.ErrorMessageTxtFlag = "";
      this.SuccessMessageTxtFlag = 0;
      this.SuccessMessageTxt = "";
      this.totalActiveAwards = 0;
      this.totalPendingAwards = 0;
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      this.resetMessageTxt();

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/show";
      var upload = {
        UserInterface: 2,
        MemberId: this.MemberId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      // thisIns.pageLoadingOn()
      thisIns.ProgessStart = 1;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.ProgessStart = 0;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records[0];
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.SuccessMessageTxtFlag = 1;
            thisIns.SuccessMessageTxt = output;
            thisIns.rows = records;
          } else {
            thisIns.ErrorMessageTxtFlag = 1;
            thisIns.ErrorMessageTxt = output;
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
  },
  beforeMount() {
    console.log(this.$route.query.id);
    this.MemberId = this.$route.query.id;

    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? 0 : RoleId;

    console.log("token=" + token + ", RoleId=" + RoleId);
    this.RoleId = RoleId;
    this.refreshPageData();
    // this.$router.push("/access-denied");
  },
};
</script>


<style lang="scss">
#profile-page4 {
  .rounded-card {
    border-radius: 24px;
  }
  .v-tab {
    font-size: 14px;
    // color: black;
  }
  .v-app-bar {
    background-color: #0097d7;
  }
  #app-bar-icon {
    margin-left: 0%;
    color: #edbe38;
    font-size: 20px;
  }
  #heading {
    font-size: 17px;
    // font-weight:normal;
    font-family: "Poppins";
    // text-shadow: 2px 2px 5px #bdbdbd;
    // text-shadow: 2px 2px 5px #90A4AE;
    // text-shadow: 2px 2px 5px #757575;
  }
  #v-card {
    background-color: white;
    border-radius: 24px;
    // font-weight: bolder;
  }
  #card-text {
    font-family: "Merriweather";
    color: black;
    // font-size:10px;
  }
  #view-button {
    color: white;
    background-color: #0097d7;
    font-family: "Merriweather";
    font-size:11px;
    font-weight: bold;
  }
  #edit-button {
    color: black;
    background-color: orange;
    font-family: "Merriweather";
    font-size:11px;
    font-weight: bold;
  }
  #add-button {
    color: white;
    background-color: green;
    font-family: "Merriweather";
  }
  #edit-icon {
    font-size: large;
    color: orange;
  }
  .v-expansion-panel-header {
    height: 36px;
  }
  // .v-expansion-panels > .v-expansion-panel > .expand-icon{
  //   color: white;
  //   size: 500px;
  // }
}
</style>
<template>
  <div id="add-profession">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          {{ pageTitle }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-container>
          <v-row wrap>
            <v-col align="start" cols="12" md="12">
              <p class="notes-title"><u>Show to all:</u></p>
              <ul class="notes-content">
                <li>
                  This value will show your data to all the registered members
                  within the Members Portal and also in other public websites of
                  JCI India.
                </li>
              </ul>
              <p class="notes-title"><u>Show only within Members Portal:</u></p>
              <ul class="notes-content">
                <li>
                  This value will show your data to all the registered members
                  within the Members Portal but will not show in other public
                  websites of JCI India.
                </li>
              </ul>
              <p class="notes-title"><u>Show only within own LOM:</u></p>
              <ul class="notes-content">
                <li>
                  This value will show your data to all the registered members
                  within the Members Portal but will not show in other public
                  websites of JCI India.
                </li>
                <li>
                  Other registered LOM members cannot view your data and will
                  not be shown in other public websites of JCI India.
                </li>
              </ul>
              <p class="notes-title"><u>Do not show anywhere:</u></p>
              <ul class="notes-content">
                <li>This value will not show your data to anybody anywhere.</li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    addRecordPrompt: {
      type: Boolean,
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      pageTitle: "Settings definition",

      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      // this.$emit('Member', this.NewMember);
      this.$emit("hideAddRecordPrompt");
    },
  },
  beforeMount() {},
};
</script>
<style lang="scss">
#add-profession {
  .notes-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 2.5em;
  }
  .notes-content {
    font-size: 14px;
    line-height: 2.5em;
  }
}
</style>
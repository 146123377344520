<template>
  <div class="card card-custom">
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          Change Password
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="700"
      >
    <!--begin::Form-->
    <v-form ref="form1" v-model="valid2" lazy-validation>
      <!--begin::Card-->
      <b-card header-tag="header" footer-tag="footer">
        <!--begin::Header-->
        <template v-slot:header>
          <h3 class="card-label font-weight-bolder text-dark">
            Change Password
          </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1"
            >Change your account password</span
          >
        </template>
        <!--end::Header-->
        <!--begin::Body-->

        <v-row v-if="!OtpSendFlag">
          <v-col align="center">
            <v-btn
            @click="sendOtp"
            rounded
            mediam
            color="purple"
            class="font-size-h6 px-3 mr-2 white--text"> Send Otp </v-btn>
          </v-col>
        </v-row>

        <div v-if="OtpSendFlag">
        <v-container>
          <v-row wrap>
            <v-col align="start" cols="12" md="12">
              <p style="font-size:14px; font-weight:bold;">
                Notes:
                <ol>
                  <li>
                    Password should be of minimum 6 characters.
                  </li>
                </ol>
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
        <v-row wrap>
          <v-col align="start" justify="center" cols="12" md="3">
            <label class="col-form-label">Enter Otp</label>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              v-model="Otp"
              :rules="OtpRules"
              hint="Enter Otp"
              val-icon-success="done"
              background-color="#F3F6F9"
              class="form-control form-control-lg form-control-solid"
              validate-on-blur
              rounded
              solo
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col align="start" justify="center" cols="12" md="3">
            <label class="col-form-label">Old Password</label>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              v-model="CurrentPassword"
              :rules="CurrentPasswordRules"
              hint="Current Password"
              type="password"
              val-icon-success="done"
              background-color="#F3F6F9"
              class="form-control form-control-lg form-control-solid"
              validate-on-blur
              rounded
              solo
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col align="start" justify="center" cols="12" md="3">
            <label class="col-form-label">New Password</label>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              v-model="NewPassword"
              :rules="NewPasswordRules"
              hint="New Password"
              type="password"
              val-icon-success="done"
              background-color="#F3F6F9"
              class="form-control form-control-lg form-control-solid"
              validate-on-blur
              rounded
              solo
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col align="start" justify="center" cols="12" md="3">
            <label class="col-form-label">Confirm Password</label>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              v-model="ConfirmPassword"
              :rules="ConfirmPasswordRules"
              hint="Confirm Password"
              type="password"
              val-icon-success="done"
              background-color="#F3F6F9"
              class="form-control form-control-lg form-control-solid"
              validate-on-blur
              rounded
              solo
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      </div>

        <!--end::Body-->
        <!--begin::Footer-->
        <template v-slot:footer>
          <div class="card-toolbar text-center">
            <v-btn
              v-if="OtpSendFlag"
              type="submit"
              @click.prevent="submitForm"
              elevation="30"
              :loading="SubmitFlag"
              shaped
              tile
              color="#88b719"
              class="btn btn-success mr-2"
            >
              <v-icon left>mdi-content-save-outline</v-icon>
              Save
            </v-btn>
            <v-btn
            @click="closePrompt"
            elevation="30"
            shaped
            tile
            mediam
            color="#a52a2a"
            class="font-size-h6 px-5 mr-2 white--text"
          >Close</v-btn>
          </div>
        </template>
        <!--end::Footer-->
      </b-card>
      <!--end::Card-->
    </v-form>
      </v-sheet>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PASSWORD } from "@/core/services/store/auth.module";
import KTUtil from "@/assets/js/components/util";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";

export default {
  name: "ChangePassword",
  mixins: [common],
  props: {
    member: {
      required: true,
    },
  },
  data() {
    return {
      valid2: true,
      SubmitFlag: false,

      SendOtpLoadingFlag: false,
      OtpSendFlag: false,

      password: "",
      status: "",
      valid: true,

      MemberId: "",

      Otp: "",
      OtpRules: [(v) => !!v || "Otp is required"],

      CurrentPassword: "",
      CurrentPasswordRules: [(v) => !!v || "Current password is required"],

      NewPassword: "",
      NewPasswordRules: [(v) => !!v || "New password is required"],

      ConfirmPassword: "",
      ConfirmPasswordRules: [(v) => !!v || "Confirm password is required"],
    };
  },
  watch: {
    // member: function () {
    //   console.log("watch member");
    //   console.log(" member=" + JSON.stringify(this.member));
    //   // this.MemberId = this.member.MemberId;
    //   var MemberId = this.member.MemberId;
    //   console.log({MemberId});
    // },
  },
  mounted() {},
  methods: {
    save() {
      this.fv.validate();

      this.fv.on("core.form.valid", () => {
        var password = this.$refs.new_password.value;
        const submitButton = this.$refs["kt_save_changes"];

        // set spinner to submit button
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send update request
          this.$store
            .dispatch(UPDATE_PASSWORD, { password })
            // go to which page after successfully update
            .then(() => this.$router.push({ name: "dashboard" }));

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      });

      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      });
    },
    cancel() {
      this.fv.resetForm();
      this.$refs.current_password.value = "";
      this.$refs.new_password.value = "";
      this.$refs.verify_password.value = "";
    },
    refreshPageData() {
      console.log("refreshPageData called");
      console.log(" member=" + JSON.stringify(this.member));
      this.MemberId = this.member.MemberId;
      var MemberId = this.member.MemberId;
      console.log({ MemberId });
    },
    sendOtp() {
      console.log("sendOtp is called");
      var MembershipId = this.member.MembershipId;
      console.log({ MembershipId });

      if (MembershipId > 0) {
        this.SendOtpLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/users/send-otp";
        var upload = {
          UserInterface: 1,
          MembershipId: MembershipId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SendOtpLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.OtpSendFlag = true;
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SendOtpLoadingFlag = false;
          });
      } else {
        var message = "";
        if (validate == false) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var MemberId = this.MemberId;

      if (validate1 && MemberId > 0) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/change-password";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var upload = {
          MemberId: this.MemberId,
          Otp: this.Otp,
          CurrentPassword: this.CurrentPassword,
          NewPassword: this.NewPassword,
          ConfirmPassword: this.ConfirmPassword,
        };
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              // thisIns.toast("success", output);
              // thisIns.resetForm();
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        if (MemberId == 0) {
          message += "Member id is invalid";
        }
        this.toast("error", message);
      }
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt");
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>

<template>
  <div class="card card-custom">
    <v-app-bar
      color="primary"
      dense
      dark
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-toolbar-title> Address Information </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon dark @click="closePrompt">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto"
      max-height="600"
    >
      <!--begin::Form-->
      <v-form ref="form1" v-model="valid2" lazy-validation>
        <!--begin::Card-->
        <b-card header-tag="header" footer-tag="footer">
          <!--begin::Header-->
          <template v-slot:header>
            <h3 class="card-label font-weight-bolder text-dark">
              Address Information
            </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1"
              >Change your address details</span
            >
          </template>
          <!--end::Header-->
          <!--begin::Body-->
          <v-row wrap>
            <v-col align="start" justify="center" cols="12" md="3">
              <label class="col-form-label">Street 1</label>
            </v-col>
            <v-col cols="12" md="9">
              <v-text-field
                v-model="Street1"
                :rules="Street1Rules"
                hint="Street 1"
                type="text"
                val-icon-success="done"
                background-color="#F3F6F9"
                class="form-control form-control-lg form-control-solid"
                validate-on-blur
                rounded
                solo
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="start" justify="center" cols="12" md="3">
              <label class="col-form-label">Street 2</label>
            </v-col>
            <v-col cols="12" md="9">
              <v-text-field
                v-model="Street2"
                :rules="Street2Rules"
                hint="Street 2"
                type="text"
                val-icon-success="done"
                background-color="#F3F6F9"
                class="form-control form-control-lg form-control-solid"
                validate-on-blur
                rounded
                solo
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="start" justify="center" cols="12" md="3">
              <label class="col-form-label">Area</label>
            </v-col>
            <v-col cols="12" md="9">
              <v-text-field
                v-model="Area"
                :rules="AreaRules"
                hint="Area"
                type="text"
                val-icon-success="done"
                background-color="#F3F6F9"
                class="form-control form-control-lg form-control-solid"
                validate-on-blur
                rounded
                solo
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="start" justify="center" cols="12" md="3">
              <label class="col-form-label">State</label>
            </v-col>
            <v-col cols="12" md="9">
              <v-autocomplete
                :reduce="(option) => option.value"
                :loading="StateCodeOptionsLoading"
                :items="StateCodeOptions"
                :rules="StateCodeRules"
                v-model="StateCode"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="start" justify="center" cols="12" md="3">
              <label class="col-form-label">District</label>
            </v-col>
            <v-col cols="12" md="9">
              <v-autocomplete
                :reduce="(option) => option.value"
                :loading="DistrictCodeOptionsLoading"
                :items="DistrictCodeOptions"
                :rules="DistrictCodeRules"
                v-model="DistrictCode"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="start" justify="center" cols="12" md="3">
              <label class="col-form-label">City</label>
            </v-col>
            <v-col cols="12" md="9">
              <v-autocomplete
                :reduce="(option) => option.value"
                :loading="CityCodeOptionsLoading"
                :items="CityCodeOptions"
                :rules="CityCodeRules"
                v-model="CityCode"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="start" justify="center" cols="12" md="3">
              <label class="col-form-label">Pincode</label>
            </v-col>
            <v-col cols="12" md="9">
              <v-text-field
                v-model="Pincode"
                :rules="PincodeRules"
                hint="Pincode"
                type="number"
                val-icon-success="done"
                background-color="#F3F6F9"
                class="form-control form-control-lg form-control-solid"
                validate-on-blur
                rounded
                solo
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <!--end::Body-->
          <!--begin::Footer-->
          <template v-slot:footer>
            <div class="card-toolbar text-right">
              <v-btn
                type="submit"
                @click.prevent="submitForm"
                elevation="30"
                :loading="SubmitFlag"
                shaped
                tile
                color="#88b719"
                class="btn btn-success mr-2"
              >
                <v-icon left>mdi-content-save-outline</v-icon>
                Save
              </v-btn>

              <v-btn
                @click="closePrompt"
                elevation="30"
                shaped
                tile
                mediam
                color="#a52a2a"
                class="font-size-h6 px-5 mr-2 white--text"
                >Close</v-btn
              >
            </div>
          </template>
          <!--end::Footer-->
        </b-card>
        <!--end::Card-->
      </v-form>
    </v-sheet>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PASSWORD } from "@/core/services/store/auth.module";
import KTUtil from "@/assets/js/components/util";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";

export default {
  name: "ChangePassword",
  mixins: [common],
  props: {
    memberData: {
      required: true,
    },
  },
  data() {
    return {
      valid2: true,
      SubmitFlag: false,
      ResultFlag: false,

      password: "",
      status: "",
      valid: true,

      MemberId: "",
      AddressId: "",
      member: {},

      Address: {},

      Street1: "",
      Street1Rules: [(v) => !!v || "Street 1 is required"],

      Street2: "",
      Street2Rules: [],

      Area: "",
      AreaRules: [],

      Pincode: "",
      PincodeRules: [(v) => !!v || "Pincode is required"],

      StateCodeRules: [(v) => !!v || "State is required"],
      StateCode: "",
      StateCodeOptions: [],
      StateCodeOptionsLoading: false,

      DistrictCodeRules: [(v) => !!v || "District is required"],
      DistrictCode: "",
      DistrictCodeOptions: [],
      DistrictCodeOptionsLoading: false,

      CityCodeRules: [(v) => !!v || "City is required"],
      CityCode: "",
      CityCodeOptions: [],
      CityCodeOptionsLoading: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {
    member: function () {
      console.log("watch member contact");
      var member = this.member;
      console.log("member=" + JSON.stringify(member));
      var address = member.hasOwnProperty("Address") ? member.Address[0] : {};
      console.log("address=" + JSON.stringify(address));
      this.Address = address;
      this.MemberId = member.hasOwnProperty("MemberId") ? member.MemberId : "";
      this.Street1 = address.hasOwnProperty("Street1") ? address.Street1 : "";
      this.Street2 = address.hasOwnProperty("Street2") ? address.Street2 : "";
      this.Area = address.hasOwnProperty("Area") ? address.Area : "";
      this.Pincode = address.hasOwnProperty("Pincode") ? address.Pincode : "";
      this.StateCode = address.hasOwnProperty("StateId") ? address.StateId : "";
      this.DistrictCode = address.hasOwnProperty("DistrictId")
        ? address.DistrictId
        : "";
      this.CityCode = address.hasOwnProperty("CityId") ? address.CityId : "";
    },
    StateCodeOptions: function () {
      console.log("watch StateCodeOptions");
      this.LoadingFlag = false;
      this.StateCodeOptionsLoading = false;
    },
    StateCode: function () {
      console.log("watch StateCode");
      this.LoadingFlag = true;
      this.DistrictCodeOptionsLoading = true;
      this.getDistrictCodeOptions(
        "DistrictCode",
        "DistrictCodeOptions",
        this.StateCode
      );
    },
    DistrictCodeOptions: function () {
      console.log("watch DistrictCodeOptions");
      this.LoadingFlag = false;
      this.DistrictCodeOptionsLoading = false;
    },
    DistrictCode: function () {
      console.log("watch DistrictCode");
      this.LoadingFlag = true;
      this.CityCodeOptionsLoading = true;
      this.getCityCodeOptions("CityCode", "CityCodeOptions", this.DistrictCode);
    },
    CityCodeOptions: function () {
      console.log("watch CityCodeOptions");
      this.LoadingFlag = false;
      this.CityCodeOptionsLoading = false;
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called contact");
      var memberData = this.memberData;
      console.log({ memberData });
      this.member = memberData;
      this.StateCodeOptionsLoading = true;
      this.getStateCodeOptions("StateCode", "StateCodeOptions");
    },
    getStateCodeOptions(source, destination) {
      console.log("getStateCodeOptions called");
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/states/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getDistrictCodeOptions(source, destination, StateCode) {
      console.log("getDistrictCodeOptions called StateCode=" + StateCode);
      if (source != "" && destination != "" && StateCode != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityCodeOptions(source, destination, DistrictCode) {
      console.log("getCityCodeOptions called DistrictCode=" + DistrictCode);
      if (source != "" && destination != "" && DistrictCode != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    save() {
      this.fv.validate();

      this.fv.on("core.form.valid", () => {
        var password = this.$refs.new_password.value;
        const submitButton = this.$refs["kt_save_changes"];

        // set spinner to submit button
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send update request
          this.$store
            .dispatch(UPDATE_PASSWORD, { password })
            // go to which page after successfully update
            .then(() => this.$router.push({ name: "dashboard" }));

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      });

      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      });
    },
    cancel() {
      this.fv.resetForm();
      this.$refs.current_password.value = "";
      this.$refs.new_password.value = "";
      this.$refs.verify_password.value = "";
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var MemberId = this.MemberId;
      console.log(" member=" + JSON.stringify(this.member));

      // this.rows = [];

      if (validate1 && MemberId > 0) {
        this.SubmitFlag = true;
        this.ResultFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/contact-update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var upload = {
          MemberId: this.MemberId,
          Street1: this.Street1,
          Street2: this.Street2,
          Area: this.Area,
          Pincode: this.Pincode,
          CityId: this.CityCode,
          DistrictId: this.DistrictCode,
          StateId: this.StateCode,
        };
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.toast("success", output);
              thisIns.closePrompt();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        if (MemberId == 0) {
          message += "Member id is invalid";
        }
        this.toast("error", message);
      }
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt",this.ResultFlag);
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

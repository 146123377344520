<template>
  <div id="address-information">
    <v-card id="v-card" v-if="StartupLoadingFlag">
      <v-container>
        <v-row wrap>
          <v-col cols="12" xl="6" md="6" sm="6" v-for="n in 4" :key="n">
            <br />
            <br />
            <v-skeleton-loader
              v-bind="attrs"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      elevation="4"
      outlined
      shaped
      v-if="!StartupLoadingFlag"
      id="v-card"
    >
      <br />
      <v-row wrap>
        <v-col cols="9" lg="9">
          <h3 class="text-dark lg-ml-3 ml-3">
            <i id="app-bar-icon" class="fas fa-house-user"></i>&nbsp;&nbsp;
            <b id="heading">Address Information</b>
          </h3>
        </v-col>
        <v-col cols="2" lg="3">
          <p
            class="btn btn-sm font-weight-bolder text-uppercase"
            id="edit-button"
            small
            @click.prevent="openRecordPrompt"
          >
            Edit
          </p>
        </v-col>
      </v-row>

      <v-card-text style="padding-left: 5%; padding-right: 0%" id="card-text">
        <v-container class="py-0 mb-4">
          <v-row wrap>
            <h6>
              {{ rows.Street1 }} , <br />
              {{ rows.Street2 }} , <br />
              {{ rows.Area }} , <br />
              {{ rows.CityName }}, {{ rows.DistrictName }}, <br />
              {{ rows.StateName }} <br />
              Pincode - {{ rows.Pincode }}
            </h6>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="addRecordPrompt" persistent max-width="80%">
      <KTAddressInformation
        v-if="UserFlag && addRecordPrompt"
        :memberData="rows"
        @hideAddRecordPrompt="hideAddRecordPrompt"
      ></KTAddressInformation>
    </v-dialog>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import Swal from "sweetalert2";

import KTAddressInformation from "@/view/pages/profile/my-profile/AddressInformation";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {
    KTAddressInformation,
  },

  data() {
    return {
      StartupLoadingFlag: false,
      MemberId: 0,
      rows: {},
      UserFlag: false,
      addRecordPrompt: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");
      this.StartupLoadingFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/show";
      var upload = {
        UserInterface: 1,
        MemberId: this.MemberId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          thisIns.StartupLoadingFlag = false;

          thisIns.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records[0];
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.rows = records;
            console.log(thisIns.rows);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          // this.toast("error", output);
          this.StartupLoadingFlag = false;
        });
    },

    openRecordPrompt() {
      console.log("openRecordPrompt called");
      this.addRecordPrompt = true;
    },
    hideAddRecordPrompt(flag) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log(flag);
      if (flag) {
        this.getTableRecords();
      }
    },
  },
  mounted() {},
  beforeMount() {
    console.log("beforeMount called");
    console.log(this.$route.query.id);
    // this.MemberId = this.$route.query.id;
    var MemberId = this.$session.get("MemberId");
    MemberId = MemberId == (null || undefined) ? 0 : MemberId;
    console.log("my-profile beforeMount called");

    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    if (token == 0) {
      this.$router.push("/login");
    } else {
      this.UserFlag = true;
      this.MemberId = MemberId;
      this.refreshPageData();
    }
  },
};
</script>
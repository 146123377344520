<template>
  <div id="individual-display-data">
    <v-overlay :value="DeleteLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container v-if="tableOptions1.FloatingButtonFlag">
      <v-speed-dial
        v-model="tableOptions1.FloatingButton.fab"
        :top="tableOptions1.FloatingButton.top"
        :bottom="tableOptions1.FloatingButton.bottom"
        :right="tableOptions1.FloatingButton.right"
        :left="tableOptions1.FloatingButton.left"
        :direction="tableOptions1.FloatingButton.direction"
        :open-on-hover="tableOptions1.FloatingButton.hover"
        :transition="tableOptions1.FloatingButton.transition"
        :fixed="tableOptions1.FloatingButton.fixed"
      >
        <template v-slot:activator>
          <v-btn
            v-model="tableOptions1.FloatingButton.fab"
            color="blue darken-2"
            dark
            fab
          >
            <v-icon v-if="tableOptions1.FloatingButton.fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-account-circle </v-icon>
          </v-btn>
        </template>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="indigo"
              @click.prevent="addMobileNoPrompt = true"
              v-if="tableOptions1.FloatingButton.CreateButtonFlag"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Create</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="green"
              v-if="tableOptions1.FloatingButton.EditButtonFlag"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="red"
              v-if="tableOptions1.FloatingButton.DeleteButtonFlag"
              @click.prevent="deleteDataAlert"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </v-speed-dial>
    </v-container>
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          {{ pageTitle }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-card-text>
          <v-card-subtitle class="text-h5">
            <h5>{{ pageDescription }}</h5>
          </v-card-subtitle>

          <v-container class="py-0" v-if="SearchFlag">
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                variant="primary"
                type="grow"
                label="Loading..."
              ></b-spinner>
            </div>
          </v-container>

          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                @click.prevent="refreshPageData"
                :loading="SearchFlag"
                target="_blank"
                color="#4387f4"
                class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
              >
                <!-- <v-icon dark> mdi-file-excel </v-icon> -->
                Refresh
              </v-btn>
              <!-- <v-btn
              v-if="tableOptions1.CreateButtonFlag"
              @click.prevent="addMobileNoPrompt = true"
              target="_blank"
              color="#0f9d58"
              class="
                btn btn-primary
                font-size-h6
                px-15
                py-4
                my-3
                mr-3
                white--text
              "
            >
              <v-icon dark> mdi-file-excel </v-icon>
              Add
            </v-btn> -->
            </v-col>
          </v-row>

          <v-dialog v-model="addMobileNoPrompt" persistent width="auto">
            <member-mobile-no-change
              pageDescription="Change Mobile number"
              :addRecordPrompt="addMobileNoPrompt"
              @hideAddRecordPrompt="hideMobileNoPrompt"
              v-if="addMobileNoPrompt"
            ></member-mobile-no-change>
          </v-dialog>

          <v-dialog v-model="addEmailIdPrompt" persistent width="auto">
            <member-email-id-change
              pageDescription="Change Email Id"
              :addRecordPrompt="addEmailIdPrompt"
              @hideAddRecordPrompt="hideEmailIdPrompt"
              v-if="addEmailIdPrompt"
            ></member-email-id-change>
          </v-dialog>

          <v-container class="py-0">
            <v-form
              ref="form2"
              v-model="valid2"
              lazy-validation
              v-on:submit.prevent="verifyOtp"
            >
              <v-row wrap>
                <v-col align="start" justify="center" cols="12" md="3">
                  <h4>WhatsApp No</h4>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    v-model="WhatsappNo"
                    :rules="WhatsappNoRules"
                    :counter="10"
                    :disabled="false"
                    v-mask="'##########'"
                    val-icon-success="done"
                    hint="Whatsapp Number"
                    required
                    outlined
                    dense
                  ></v-text-field>
                  <v-btn
                    small
                    color="#00a4ef"
                    dark
                    :loading="submitFormLoadingFlag"
                    @click.prevent="submitForm"
                  >
                    Submit
                  </v-btn>
                  &nbsp;
                  <v-btn
                    @click="closePrompt"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#a52a2a"
                    class="font-size-h6 px-5 mr-2 white--text"
                    >Close</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-sheet>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import MemberMobileNoChange from "@/view/pages/profile/my-profile/MemberMobileNoChange";
import MemberEmailIdChange from "@/view/pages/profile/my-profile/MemberEmailIdChange";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    MemberMobileNoChange,
    MemberEmailIdChange,
  },
  props: {
    MemberId: {
      required: true,
    },
  },
  data() {
    return {
      pageTitle: "Communication details",
      pageDescription: "Update your communication details",

      DeleteLoadingFlag: false,

      valid2: true,
      submitFormLoadingFlag: false,

      SearchFlag: false,
      ResultFlag: false,

      selected: [],
      selectedData: {},
      search: "",

      FloatingButton: {},

      tableColumns1: [],
      tableOptions1: {
        FloatingButton: {},
      },
      tableData1: [],

      addMobileNoPrompt: false,
      addEmailIdPrompt: false,

      editRecordPrompt: false,
      previewRecordPrompt: false,

      MobileNo: "",
      MobileNoRules: [(v) => !!v || "Mobile No is required"],

      EmailId: "",
      EmailIdRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      MobileNoVerified: 0,
      EmailIdVerified: 0,

      SuccessIcon: "",
      DangerIcon: "",

      SuccessColor: "",
      DangerColor: "",

      member: {},

      WhatsappNo: "",
      WhatsappNoRules: [(v) => !!v || "Whatsapp No is required"],
    };
  },
  computed: {},
  watch: {
    member: function () {
      console.log("watch member contact");
      var member = this.member;
      console.log("member=" + JSON.stringify(member));
      this.MemberId = member.hasOwnProperty("MemberId") ? member.MemberId : "";
      this.WhatsappNo = member.hasOwnProperty("WhatsappNo")
        ? member.WhatsappNo
        : "";
      this.MobileNo = member.hasOwnProperty("MobileNo") ? member.MobileNo : "";
      this.MobileNoVerified = member.hasOwnProperty("MobileNoVerified")
        ? member.MobileNoVerified
        : "";
      this.EmailId = member.hasOwnProperty("EmailId") ? member.EmailId : "";
      this.EmailIdVerified = member.hasOwnProperty("EmailIdVerified")
        ? member.EmailIdVerified
        : "";
      this.SuccessIcon = member.hasOwnProperty("SuccessIcon")
        ? member.SuccessIcon
        : "";
      this.DangerIcon = member.hasOwnProperty("DangerIcon")
        ? member.DangerIcon
        : "";
      this.SuccessIcon = member.hasOwnProperty("SuccessIcon")
        ? member.SuccessIcon
        : "";
      this.SuccessColor = member.hasOwnProperty("SuccessColor")
        ? member.SuccessColor
        : "";
      this.DangerColor = member.hasOwnProperty("DangerColor")
        ? member.DangerColor
        : "";
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    hideMobileNoPrompt(flag) {
      console.log("hideMobileNoPrompt called");
      this.addMobileNoPrompt = false;
      console.log({flag});
      if (flag) {
        this.getTableRecords();
      }
    },
    hideEmailIdPrompt() {
      console.log("hideEmailIdPrompt called");
      this.addEmailIdPrompt = false;
      console.log({flag});
      if (flag) {
        this.getTableRecords();
      }
    },
    getTableRecords() {
      console.log("getTableRecords is called");

      // var validate1 = this.$refs.form2.validate();
      var validate1 = true;
      console.log("validate1=" + validate1);

      if (validate1) {
        this.SearchFlag = true;
        this.tableData1 = [];
        this.selected = [];
        this.selectedData = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members-details";
        var upload = {
          UserInterface: 1,
          MemberId: this.MemberId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            // thisIns.tableOptions1 = records.TableOptions;

            if (flag == 1) {
              // thisIns.toast("success", output);
              thisIns.member = records;
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form2.validate();
      // var validate1 = false;
      console.log("validate1=" + validate1);

      if (validate1) {
        this.submitFormLoadingFlag = true;
        this.ResultFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/personal-update";
        var upload = {
          UserInterface: 2,
          WhatsappNo: this.WhatsappNo,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.submitFormLoadingFlag = false;
            thisIns.ResultFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.submitFormLoadingFlag = false;
          });
      } else {
        var message = "";
        if (this.WhatsappNo == "") {
          message = "Whatsapp number should not be empty. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    closePrompt() {
      console.log("closePrompt called");
      this.addRecordPrompt = false;
      this.$emit("hideAddRecordPrompt", this.ResultFlag);
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<template>
  <div id="address-information">
    <v-card id="v-card" v-if="StartupLoadingFlag">
      <v-container>
        <v-row wrap>
          <v-col cols="12" xl="6" md="6" sm="6" v-for="n in 4" :key="n">
            <br />
            <br />
            <v-skeleton-loader
              v-bind="attrs"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      elevation="4"
      outlined
      shaped
      v-if="!StartupLoadingFlag"
      id="v-card"
    >
      <br />

      <v-row wrap>
        <v-col cols="12">
          <h3 class="text-dark lg-ml-3 ml-3">
            <i id="app-bar-icon" class="fas fa-hand-holding-heart"></i
            >&nbsp;&nbsp;
            <b id="heading">JCI India Foundation Contribution</b>
          </h3>
        </v-col>
      </v-row>

      <v-card-text style="padding-left: 1%; padding-right: 0%" id="card-text">
        <v-container class="py-0" v-if="titles.length > 0">
          <h6 v-if="NotesFlag">
            <span class="text-danger">*</span> Note :
            <span class="text-dark"
              >Data shall be shown only from the year 2021 onwards.</span
            >
          </h6>
          <br />
        </v-container>
        <v-container class="py-0" v-if="titles.length > 0">
          <v-row wrap>
            <v-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              v-for="(item, index) in titles"
              :key="index"
            >
              <v-row>
                <v-col cols="9" lg="9">
                  <h5>
                    {{ index + 1 }}.
                    <span class="text-dark">
                      {{ item.TitleName }}
                      <!-- &nbsp; - &nbsp;
                      {{ item.PaidDate }} -->
                    </span>
                  </h5>
                </v-col>
                <v-col cols="3" lg="2">
                  <button
                    v-if="item.TransactionTypeId == 2"
                    v-bind="attrs"
                    v-on="on"
                    style="border: none; background: none; outline: none;"
                  >
                    <span style="font-size: medium">NA</span>
                  </button>
                  <v-btn
                    v-if="item.ReceiptPath != '' && item.TransactionTypeId == 1"
                    :href="item.ReceiptPath"
                    target="_blank"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark color="blue" medium> mdi-download </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import Swal from "sweetalert2";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {},

  data() {
    return {
      StartupLoadingFlag: false,
      MemberId: 0,

      titles: [],

      tableColumns1: [],
      pageTitle: "JCI India Foundation Contribution",
      pageDescription: "List of contribution to JCI India",
      selected: [],
      search: "",
      rows: [],

      NotesFlag: false,
    };
  },
  computed: {},
  watch: {
    rows: function () {
      console.log("watch rows");
      var rows = this.rows;
      var n1 =
        typeof rows === "object" && rows !== null
          ? Object.keys(rows).length
          : 0;
      console.log("n1=" + n1 + ", rows=" + JSON.stringify(rows));
      if (n1 > 0) {
        this.ResultFlag = 1;
        this.address = rows.hasOwnProperty("Address") ? rows.Address[0] : {};
        this.contact = rows.hasOwnProperty("Contact") ? rows.Contact : [];
        this.designation = rows.hasOwnProperty("OfficesHeld")
          ? rows.OfficesHeld
          : [];
        this.dues = rows.hasOwnProperty("Dues") ? rows.Dues : [];
        this.social = rows.hasOwnProperty("Social") ? rows.Social : [];
        this.titles = rows.hasOwnProperty("Titles") ? rows.Titles : [];
        this.ProfessionData = rows.hasOwnProperty("Profession")
          ? rows.Profession
          : [];
        this.ProfessionHeaders = rows.hasOwnProperty("ProfessionTableHeader")
          ? rows.ProfessionTableHeader
          : [];
        this.ProfessionOptions = rows.hasOwnProperty("ProfessionTableOptions")
          ? rows.ProfessionTableOptions
          : [];
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");
      this.StartupLoadingFlag = true;
      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/show";
      var upload = {
        UserInterface: 1,
        MemberId: this.MemberId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          thisIns.StartupLoadingFlag = false;

          thisIns.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records[0];
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.rows = records;
            console.log(thisIns.rows);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          this.StartupLoadingFlag = false;
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          this.toast("error", output);
        });
    },
  },
  mounted() {},
  beforeMount() {
    console.log("beforeMount called");
    console.log(this.$route.query.id);
    // this.MemberId = this.$route.query.id;
    var MemberId = this.$session.get("MemberId");
    MemberId = MemberId == (null || undefined) ? 0 : MemberId;
    console.log("my-profile beforeMount called");

    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    if (token == 0) {
      this.$router.push("/login");
    } else {
      this.UserFlag = true;
      this.MemberId = MemberId;

      var RoleId = this.$session.get("RoleId");
      RoleId = RoleId == (null || undefined) ? 0 : RoleId;

      console.log("token=" + token + ", RoleId=" + RoleId);
      this.RoleId = RoleId;
      if (RoleId != "") {
        this.refreshPageData();
        this.tableColumns1.push(
          { text: "Payment Type", value: "TransactionTypeTxt" },
          { text: "Date", value: "PaidDate" },
          { text: "Register No", value: "RegisterNo" },
          { text: "Receipt No", value: "ReceiptNo" },
          { text: "Title", value: "TitleName" },
          { text: "Receipt", value: "ReceiptPath" }
        );
        console.log(this.tableColumns1);
      }

      this.refreshPageData();
    }
  },
};
</script>
<template>
  <div id="individual-display-data">
    <v-overlay :value="DeleteLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-toolbar color="#8950FC">
      <v-toolbar-title>
        <h1 class="text-white">
          <span class="headline"> {{ pageTitle }} </span>
        </h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-card>
      <v-form ref="form1" v-model="valid1" lazy-validation>
        <v-card-text>
          <v-card-subtitle class="text-h5">
            <h5>{{ pageDescription }}</h5>
          </v-card-subtitle>

          <v-container>
            <v-row wrap>
              <v-col
                align="start"
                cols="12"
                md="12"
                style="font-size: 14px; font-weight: bold; line-height: 2.5em"
              >
                <p>Notes:</p>
                <ol>
                  <li>
                    Your data will be visible to NHQ and your LOM president
                    irrespective of your privacy settings.
                  </li>
                  <li>
                    If you hold a designation in either LGB, ZGB or NGB your
                    data will be shown in the Directory information page of JCI
                    India website and mobile APP irrespective of your privacy
                    settings.
                  </li>
                </ol>
                <v-btn
                  color="blue"
                  class="btn btn-primary font-size-h6 py-4 my-3 mr-3 text-white"
                  @click.prevent="addRecordPrompt = true"
                >
                  Setting values definition
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <v-dialog v-model="addRecordPrompt" persistent width="auto">
            <notes-privacy-policy
              pageDescription="Add New Profession"
              :addRecordPrompt="addRecordPrompt"
              @hideAddRecordPrompt="changeAddRecordPrompt"
              v-if="addRecordPrompt"
            ></notes-privacy-policy>
          </v-dialog>

          <v-container class="py-0" v-if="SearchFlag">
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                variant="primary"
                type="grow"
                label="Loading..."
              ></b-spinner>
            </div>
          </v-container>

          <v-container class="py-0">
            <v-row wrap>
              <v-col align="start" justify="center" cols="12" md="3">
                <label class="col-form-label">Mobile No</label>
              </v-col>
              <v-col cols="12" md="9">
                <v-select
                  :reduce="(option) => option.value"
                  :items="PrivacyMobileNoOptions"
                  :rules="PrivacyMobileNoRules"
                  v-model="PrivacyMobileNo"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="start" justify="center" cols="12" md="3">
                <label class="col-form-label">Email Id</label>
              </v-col>
              <v-col cols="12" md="9">
                <v-select
                  :reduce="(option) => option.value"
                  :items="PrivacyEmailIdOptions"
                  :rules="PrivacyEmailIdRules"
                  v-model="PrivacyEmailId"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="start" justify="center" cols="12" md="3">
                <label class="col-form-label">Address</label>
              </v-col>
              <v-col cols="12" md="9">
                <v-select
                  :reduce="(option) => option.value"
                  :items="PrivacyAddressOptions"
                  :rules="PrivacyAddressRules"
                  v-model="PrivacyAddress"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="start" justify="center" cols="12" md="3">
                <label class="col-form-label">Photo</label>
              </v-col>
              <v-col cols="12" md="9">
                <v-select
                  :reduce="(option) => option.value"
                  :items="PrivacyPhotoOptions"
                  :rules="PrivacyPhotoRules"
                  v-model="PrivacyPhoto"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="start" justify="center" cols="12" md="3">
                <label class="col-form-label">Date of Birth</label>
              </v-col>
              <v-col cols="12" md="9">
                <v-select
                  :reduce="(option) => option.value"
                  :items="PrivacyDobOptions"
                  :rules="PrivacyDobRules"
                  v-model="PrivacyDob"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <br />
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!valid1"
            @click.prevent="submitForm"
            :loading="SubmitFlag"
            elevation="30"
            shaped
            tile
            small
            color="#1db954"
            class="font-size-h6 px-10 mr-3 white--text"
          >
            Save
          </v-btn>
          <v-btn
            @click="closePrompt"
            elevation="30"
            shaped
            tile
            small
            color="#a52a2a"
            class="font-size-h6 px-5 mr-2 white--text"
            >Close</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import NotesPrivacyPolicy from "@/view/pages/profile/my-profile/NotesPrivacyPolicy";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    NotesPrivacyPolicy,
  },
  props: {
    MemberId: {
      required: true,
    },
  },
  data() {
    return {
      pageTitle: "Privacy and Policy",
      pageDescription: "Set your profile's privacy policy here",

      valid1: true,

      DeleteLoadingFlag: false,

      SubmitFlag: false,
      SearchFlag: false,
      ResultFlag: false,

      selected: [],
      selectedData: {},
      search: "",

      member: {},

      PrivacyMobileNo: "",
      PrivacyMobileNoRules: [
        (v) => !!v || "Privacy status for mobile no is required",
      ],
      PrivacyMobileNoOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Do not show anywhere" },
        { value: 2, text: "Show to all" },
        { value: 3, text: "Show only within members portal" },
        { value: 4, text: "Show only within own LOM" },
      ],

      PrivacyEmailId: "",
      PrivacyEmailIdRules: [
        (v) => !!v || "Privacy status for email id is required",
      ],
      PrivacyEmailIdOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Do not show anywhere" },
        { value: 2, text: "Show to all" },
        { value: 3, text: "Show only within members portal" },
        { value: 4, text: "Show only within own LOM" },
      ],

      PrivacyAddress: "",
      PrivacyAddressRules: [
        (v) => !!v || "Privacy status for address is required",
      ],
      PrivacyAddressOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Do not show anywhere" },
        { value: 2, text: "Show to all" },
        { value: 3, text: "Show only within members portal" },
        { value: 4, text: "Show only within own LOM" },
      ],

      PrivacyPhoto: "",
      PrivacyPhotoRules: [(v) => !!v || "Privacy status for photo is required"],
      PrivacyPhotoOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Do not show anywhere" },
        { value: 2, text: "Show to all" },
        { value: 3, text: "Show only within members portal" },
        { value: 4, text: "Show only within own LOM" },
      ],

      PrivacyDob: "",
      PrivacyDobRules: [
        (v) => !!v || "Privacy status for date of birth is required",
      ],
      PrivacyDobOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Do not show anywhere" },
        { value: 2, text: "Show to all" },
        { value: 3, text: "Show only within members portal" },
        { value: 4, text: "Show only within own LOM" },
      ],

      FloatingButton: {},

      tableColumns1: [],
      tableOptions1: {
        FloatingButton: {},
      },
      tableData1: [],

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    changeAddRecordPrompt() {
      console.log("changeAddRecordPrompt called");
      this.addRecordPrompt = false;
    },

    getTableRecords() {
      console.log("getTableRecords called");
      this.StartupLoadingFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/show";
      var upload = {
        UserInterface: 1,
        MemberId: this.MemberId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          thisIns.StartupLoadingFlag = false;

          // thisIns.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records[0];
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.member = records;
            console.log(thisIns.member);

            var member = thisIns.member;

            thisIns.PrivacyMobileNo = member.hasOwnProperty("PrivacyMobileNo")
              ? member.PrivacyMobileNo
              : "";
            thisIns.PrivacyEmailId = member.hasOwnProperty("PrivacyEmailId")
              ? member.PrivacyEmailId
              : "";
            thisIns.PrivacyAddress = member.hasOwnProperty("PrivacyAddress")
              ? member.PrivacyAddress
              : "";
            thisIns.PrivacyPhoto = member.hasOwnProperty("PrivacyPhoto")
              ? member.PrivacyPhoto
              : "";
            thisIns.PrivacyDob = member.hasOwnProperty("PrivacyDob")
              ? member.PrivacyDob
              : "";
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          // this.toast("error", output);
          this.StartupLoadingFlag = false;
        });
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      // this.rows = [];

      if (validate1) {
        this.SubmitFlag = true;
        this.ResultFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/privacy-policy-update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var upload = {
          PrivacyMobileNo: this.PrivacyMobileNo,
          PrivacyEmailId: this.PrivacyEmailId,
          PrivacyAddress: this.PrivacyAddress,
          PrivacyPhoto: this.PrivacyPhoto,
          PrivacyDob: this.PrivacyDob,
        };
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.ResultFlag = true;
              thisIns.getTableRecords();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt");
    },
  },
  beforeMount() {
    console.log("beforeMount called");
    this.refreshPageData();
  },
};
</script>

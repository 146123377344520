<template>
  <div id="subscription-details">
    <v-card id="v-card" v-if="StartupLoadingFlag">
      <v-container>
        <v-row wrap>
          <v-col cols="12" xl="6" md="6" sm="6" v-for="n in 4" :key="n">
            <br />
            <br />
            <v-skeleton-loader
              v-bind="attrs"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card id="v-card" v-if="!StartupLoadingFlag">
      <v-app-bar
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
        color="#0097d7"
      >
        <v-toolbar-title>
          <i id="app-bar-icon" class="fas fa-money-bill-wave"> </i>
          &nbsp;&nbsp;<span id="app-bar-heading"
            >Subscription Details</span
          ></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text id="card-text">
        <v-container class="py-0" v-if="dues.length > 0">
          <v-data-iterator
            :items="dues"
            :items-per-page="-1"
            hide-default-footer
          >
            <template v-slot:header> </template>
            <template v-slot:default="props">
              <v-row>
                <v-col
                  cols="12"
                  xl="3"
                  md="4"
                  sm="6"
                  v-for="item in props.items"
                  :key="item.DuesId"
                >
                  <v-card raised hover elevation="25">
                    <v-card-title>
                      <v-app-bar
                        height="35%"
                        color="#01579B"
                        dense
                        dark
                        elevate-on-scroll
                        scroll-target="#scrolling-techniques-7"
                      >
                        <v-toolbar-title>
                          <span style="font-size: medium">
                            {{ item.LomName }}
                          </span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer> </v-app-bar
                    ></v-card-title>
                    <v-card-text>
                      <v-row wrap>
                        <v-col sm="6" md="12" lg="12" xl="12" class="text-left">
                          <h6>
                            Year&nbsp;<span class="text-dark">
                              : {{ item.Year }}
                            </span>
                          </h6>
                          <h6>
                            Half&nbsp;<span class="text-dark">
                              : {{ item.HalfTxt }}
                            </span>
                          </h6>
                          <h6>
                            Date&nbsp;<span class="text-dark">
                              : {{ item.DuesDateTxt }}
                            </span>
                          </h6>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="closePrompt"
          elevation="30"
          shaped
          tile
          medium
          color="#a52a2a"
          class="font-size-h6 px-10 mr-3 white--text"
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <br />
        <br />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import Swal from "sweetalert2";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {},

  data() {
    return {
      StartupLoadingFlag: false,
      MemberId: 0,

      dues: [],

      pageTitle: "Subscription details",
      pageDescription: "List of subscription details",

      tableColumns1: [],
      selected: [],
      search: "",
      rows: [],
    };
  },
  computed: {},
  watch: {
    rows: function () {
      console.log("watch rows");
      var rows = this.rows;
      var n1 =
        typeof rows === "object" && rows !== null
          ? Object.keys(rows).length
          : 0;
      console.log("n1=" + n1 + ", rows=" + JSON.stringify(rows));
      if (n1 > 0) {
        this.ResultFlag = 1;
        this.address = rows.hasOwnProperty("Address") ? rows.Address[0] : {};
        this.contact = rows.hasOwnProperty("Contact") ? rows.Contact : [];
        this.designation = rows.hasOwnProperty("OfficesHeld")
          ? rows.OfficesHeld
          : [];
        this.dues = rows.hasOwnProperty("Dues") ? rows.Dues : [];

        this.social = rows.hasOwnProperty("Social") ? rows.Social : [];
        this.titles = rows.hasOwnProperty("Titles") ? rows.Titles : [];

        this.ProfessionData = rows.hasOwnProperty("Profession")
          ? rows.Profession
          : [];
        this.ProfessionHeaders = rows.hasOwnProperty("ProfessionTableHeader")
          ? rows.ProfessionTableHeader
          : [];
        this.ProfessionOptions = rows.hasOwnProperty("ProfessionTableOptions")
          ? rows.ProfessionTableOptions
          : [];
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");
      this.StartupLoadingFlag = true;
      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/show";
      var upload = {
        UserInterface: 1,
        MemberId: this.MemberId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          thisIns.StartupLoadingFlag = false;

          // thisIns.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records[0];
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.rows = records;
            console.log(thisIns.rows);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          this.StartupLoadingFlag = false;
          // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          this.toast("error", output);
        });
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt");
    },
  },
  mounted() {},
  beforeMount() {
    console.log("beforeMount called");
    console.log(this.$route.query.id);
    // this.MemberId = this.$route.query.id;
    var MemberId = this.$session.get("MemberId");
    MemberId = MemberId == (null || undefined) ? 0 : MemberId;
    console.log("my-profile beforeMount called");

    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    if (token == 0) {
      this.$router.push("/login");
    } else {
      this.UserFlag = true;
      this.MemberId = MemberId;

      var RoleId = this.$session.get("RoleId");
      RoleId = RoleId == (null || undefined) ? 0 : RoleId;

      console.log("token=" + token + ", RoleId=" + RoleId);
      this.RoleId = RoleId;
      if (RoleId != "") {
        this.refreshPageData();
        this.tableColumns1.push(
          { text: "Lom Name", value: "LomName" },
          { text: "Year", value: "Year" },
          { text: "Half", value: "HalfTxt" },
          { text: "Date", value: "DuesDateTxt" }
        );
      }

      this.refreshPageData();
    }
  },
};
</script>

<style lang="scss">
#subscription-details {
  .v-tab {
    font-size: 14px;
    // color: black;
  }
  .v-app-bar {
    background-color: #0097d7;
  }
  #app-bar-icon {
    margin-left: 0%;
    color: #edbe38;
    font-size: 24px;
  }
  #app-bar-heading {
    color: white;
    font-size: 18px;
    font-weight: bold;
    font-family: "Roboto Serif";
  }
}
</style>
<template>
  <div id="communication-information">
    <v-card id="v-card" v-if="StartupLoadingFlag">
      <v-container>
        <v-row wrap>
          <v-col cols="12" xl="6" md="6" sm="6" v-for="n in 4" :key="n">
            <br />
            <br />
            <v-skeleton-loader
              v-bind="attrs"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      elevation="4"
      outlined
      shaped
      v-if="!StartupLoadingFlag"
      id="v-card"
    >
      <br />

      <v-row wrap>
        <v-col cols="12">
          <h3 class="text-dark lg-ml-3 ml-3">
            <i id="app-bar-icon" class="fas fa-phone-volume"></i>&nbsp;&nbsp;
            <b id="heading">Communication Information</b>
          </h3>
        </v-col>
      </v-row>

      <v-card-text style="padding-left: 1%; padding-right: 0%" id="card-text">
        <v-container class="py-0">
          <v-row wrap>
            <v-col cols="10" lg="10">
              <h6>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" :color="MobileNoIconColor">
                      {{ MobileNoIcon }}
                    </v-icon>
                  </template>
                  <span>{{ MobileNoVerifiedTxt }}</span>
                </v-tooltip>
                <!-- <v-icon :color="MobileNoIconColor">{{ MobileNoIcon }}</v-icon> -->
                &nbsp; <span class="text-dark-50">Mobile No</span> :
                {{ member.MobileNo }}
              </h6>
            </v-col>
            <v-col cols="2" lg="2">
              <!-- <v-icon id="edit-icon" @click.prevent="addMobileNoPrompt = true">
                fas fa-edit
              </v-icon> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="edit-icon"
                    @click.prevent="addMobileNoPrompt = true"
                    v-bind="attrs"
                    v-on="on"
                  >
                    fas fa-edit
                  </v-icon>
                </template>
                <span> Edit </span>
              </v-tooltip>
            </v-col>
            <!-- </v-row>
          <v-row wrap> -->
            <v-col cols="10" lg="10">
              <h6>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" :color="EmailIdIconColor">
                      {{ EmailIdIcon }}
                    </v-icon>
                  </template>
                  <span>{{ EmailIdVerifiedTxt }}</span>
                </v-tooltip>

                <!-- <v-icon :color="EmailIconColor">{{ EmailIcon }} </v-icon> -->
                &nbsp; <span class="text-dark-50">Email</span> :
                {{ member.EmailId }}
              </h6>
            </v-col>
            <v-col cols="2" lg="2">
              <!-- <v-icon id="edit-icon" @click.prevent="addEmailIdPrompt = true">
                fas fa-edit
              </v-icon> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="edit-icon"
                    @click.prevent="addEmailIdPrompt = true"
                    v-bind="attrs"
                    v-on="on"
                  >
                    fas fa-edit
                  </v-icon>
                </template>
                <span> Edit </span>
              </v-tooltip>
            </v-col>
            <!-- </v-row>

          <v-row wrap> -->
            <v-col cols="10" lg="10">
              <h6 class="ml-2 lg-ml-9">
                &nbsp;
                <span class="text-dark-50"> WhatsApp No</span> :
                {{ member.WhatsappNo }}
              </h6>
            </v-col>
            <v-col cols="2" lg="2">
              <!-- <v-icon id="edit-icon" @click.prevent="openRecordPrompt">
                fas fa-edit
              </v-icon> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="edit-icon"
                    @click.prevent="openRecordPrompt"
                    v-bind="attrs"
                    v-on="on"
                  >
                    fas fa-edit
                  </v-icon>
                </template>
                <span> Edit </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="addMobileNoPrompt" persistent width="auto">
      <member-mobile-no-change
        pageDescription="Change Mobile number"
        :addRecordPrompt="addMobileNoPrompt"
        @hideAddRecordPrompt="hideMobileNoPrompt"
        v-if="addMobileNoPrompt"
      ></member-mobile-no-change>
    </v-dialog>

    <v-dialog v-model="addEmailIdPrompt" persistent width="auto">
      <member-email-id-change
        pageDescription="Change Email Id"
        :addRecordPrompt="addEmailIdPrompt"
        @hideAddRecordPrompt="hideEmailIdPrompt"
        v-if="addEmailIdPrompt"
      ></member-email-id-change>
    </v-dialog>

    <v-dialog v-model="addRecordPrompt" persistent max-width="80%">
      <member-communication-details
        v-if="UserFlag && addRecordPrompt"
        :MemberId="MemberId"
        @hideAddRecordPrompt="hideAddRecordPrompt"
      ></member-communication-details>
    </v-dialog>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import Swal from "sweetalert2";

import MemberMobileNoChange from "@/view/pages/profile/my-profile/MemberMobileNoChange";
import MemberEmailIdChange from "@/view/pages/profile/my-profile/MemberEmailIdChange";
import MemberCommunicationDetails from "@/view/pages/profile/profile-cards-1/MemberCommunicationDetails";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {
    MemberMobileNoChange,
    MemberEmailIdChange,
    MemberCommunicationDetails,
  },

  data() {
    return {
      StartupLoadingFlag: false,
      MemberId: 0,
      member: {},
      UserFlag: false,
      addRecordPrompt: false,

      addMobileNoPrompt: false,
      addEmailIdPrompt: false,

      WhatsappNo: "",

      MobileNo: "",
      MobileNoVerified: "",
      MobileNoVerifiedTxt: "",
      MobileNoIcon: "",
      MobileNoIconColor: "",

      EmailId: "",
      EmailIdVerified: "",
      EmailIdVerifiedTxt: "",
      EmailIdIcon: "",
      EmailIdIconColor: "",
    };
  },
  computed: {},
  watch: {
    member: function () {
      console.log("watch member contact");
      var member = this.member;
      console.log("member=" + JSON.stringify(member));
      this.MemberId = member.hasOwnProperty("MemberId") ? member.MemberId : "";
      this.WhatsappNo = member.hasOwnProperty("WhatsappNo")
        ? member.WhatsappNo
        : "";
      this.MobileNo = member.hasOwnProperty("MobileNo") ? member.MobileNo : "";
      this.MobileNoVerified = member.hasOwnProperty("MobileNoVerified")
        ? member.MobileNoVerified
        : "";
      this.MobileNoVerifiedTxt = member.hasOwnProperty("MobileNoVerified")
        ? member.MobileNoVerifiedTxt
        : "";

      this.MobileNoIcon = member.hasOwnProperty("MobileNoIcon")
        ? member.MobileNoIcon
        : "";

      this.MobileNoIconColor = member.hasOwnProperty("MobileNoIconColor")
        ? member.MobileNoIconColor
        : "";

      this.EmailId = member.hasOwnProperty("EmailId") ? member.EmailId : "";
      this.EmailIdVerified = member.hasOwnProperty("EmailIdVerified")
        ? member.EmailIdVerified
        : "";
      this.EmailIdVerifiedTxt = member.hasOwnProperty("EmailIdVerified")
        ? member.EmailIdVerifiedTxt
        : "";
      this.EmailIdIcon = member.hasOwnProperty("EmailIdIcon")
        ? member.EmailIdIcon
        : "";
      this.EmailIdIconColor = member.hasOwnProperty("EmailIdIconColor")
        ? member.EmailIdIconColor
        : "";
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords is called");

      // var validate1 = this.$refs.form2.validate();
      var validate1 = true;
      console.log("validate1=" + validate1);

      if (validate1) {
        this.SearchFlag = true;
        this.tableData1 = [];
        this.selected = [];
        this.selectedData = {};
        this.StartupLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members-details";
        var upload = {
          UserInterface: 1,
          MemberId: this.MemberId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SearchFlag = false;
            thisIns.StartupLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            // thisIns.tableOptions1 = records.TableOptions;

            if (flag == 1) {
              // thisIns.toast("success", output);
              thisIns.member = records;
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message, false);
      }
    },
    hideMobileNoPrompt(flag) {
      console.log("hideMobileNoPrompt called");
      this.addMobileNoPrompt = false;
      console.log({ flag });
      if (flag) {
        this.getTableRecords();
      }
    },
    hideEmailIdPrompt(flag) {
      console.log("hideEmailIdPrompt called");
      this.addEmailIdPrompt = false;
      console.log({ flag });
      if (flag) {
        this.getTableRecords();
      }
    },
    openRecordPrompt() {
      console.log("openRecordPrompt called");
      this.addRecordPrompt = true;
    },
    hideAddRecordPrompt(flag) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log(flag);
      if (flag) {
        this.getTableRecords();
      }
    },
  },
  mounted() {},
  beforeMount() {
    console.log("beforeMount called");
    console.log(this.$route.query.id);
    // this.MemberId = this.$route.query.id;
    var MemberId = this.$session.get("MemberId");
    MemberId = MemberId == (null || undefined) ? 0 : MemberId;
    console.log("my-profile beforeMount called");

    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    if (token == 0) {
      this.$router.push("/login");
    } else {
      this.UserFlag = true;
      this.MemberId = MemberId;
      this.refreshPageData();
    }
  },
};
</script>
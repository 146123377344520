<template>
  <div id="social-media">
    <v-card id="v-card" v-if="StartupLoadingFlag">
      <v-container>
        <v-row wrap>
          <v-col cols="12" xl="6" md="6" sm="6" v-for="n in 4" :key="n">
            <br />
            <v-skeleton-loader
              v-bind="attrs"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      elevation="4"
      outlined
      shaped
      v-if="!StartupLoadingFlag"
      id="v-card"
    >
      <br />
      <v-row wrap>
        <v-col cols="9" lg="9">
          <h3 class="text-dark lg-ml-3 ml-3">
            <i id="app-bar-icon" class="fab fa-twitter"></i>&nbsp;&nbsp;
            <b id="heading">Social Links</b>
          </h3>
        </v-col>
        <v-col cols="2" lg="2">
          <p
            class="btn btn-sm font-weight-bolder text-uppercase"
            id="edit-button"
            @click.prevent="openRecordPrompt"
          >
            Edit
          </p>
        </v-col>
      </v-row>

      <v-card-text style="padding-left: 1%; padding-right: 0%" id="card-text">
        <v-container class="py-0">
          <div class="d-flex flex-wrap mb-4">
            <div v-for="item in social" :key="item.MemberSocialId">
              <p v-if="item.SocialMediaLink !== ''">
                <a :href="item.SocialMediaLink" target="_blank">
                  <v-icon
                    id="social-icon"
                    :color="item.SocialMediaIconColor"
                    v-if="item.SocialMediaLink !== ''"
                  >
                    {{ item.SocialMediaIcon }}
                  </v-icon>
                </a>
                &nbsp; &nbsp; &nbsp;
              </p>
              <p v-else>
                <v-icon id="social-icon" :color="item.SocialMediaIconColor">
                  {{ item.SocialMediaIcon }}
                </v-icon>
                &nbsp; &nbsp; &nbsp;
              </p>
            </div>
          </div>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="addRecordPrompt" persistent max-width="80%">
      <member-social-links
        v-if="UserFlag && addRecordPrompt"
        :records="social"
        @hideAddRecordPrompt="hideAddRecordPrompt"
      ></member-social-links>
    </v-dialog>
  </div>
</template>


<script>
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import Swal from "sweetalert2";

import MemberSocialLinks from "@/view/pages/profile/my-profile/MemberSocialLinks";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {
    MemberSocialLinks,
  },

  data() {
    return {
      StartupLoadingFlag: false,
      MemberId: 0,
      rows: {},
      social: {},
      UserFlag: false,

      pageTitle: "Social media details",
      pageDescription: "List of social media links (Optional)",

      valid1: true,

      DeleteLoadingFlag: false,

      SubmitFlag: false,
      SearchFlag: false,

      selected: [],
      selectedData: {},
      search: "",

      FloatingButton: {},

      tableColumns1: [],
      tableOptions1: {
        FloatingButton: {},
      },
      tableData1: [],

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
    };
  },
  computed: {},
  watch: {
    rows: function () {
      console.log("watch rows");
      var rows = this.rows;
      var n1 =
        typeof rows === "object" && rows !== null
          ? Object.keys(rows).length
          : 0;
      console.log("n1=" + n1 + ", rows=" + JSON.stringify(rows));
      if (n1 > 0) {
        this.ResultFlag = 1;
        this.address = rows.hasOwnProperty("Address") ? rows.Address[0] : {};
        this.contact = rows.hasOwnProperty("Contact") ? rows.Contact : [];
        this.designation = rows.hasOwnProperty("OfficesHeld")
          ? rows.OfficesHeld
          : [];
        this.dues = rows.hasOwnProperty("Dues") ? rows.Dues : [];
        this.social = rows.hasOwnProperty("Social") ? rows.Social : [];
        this.titles = rows.hasOwnProperty("Titles") ? rows.Titles : [];
        this.ProfessionData = rows.hasOwnProperty("Profession")
          ? rows.Profession
          : [];
        this.ProfessionHeaders = rows.hasOwnProperty("ProfessionTableHeader")
          ? rows.ProfessionTableHeader
          : [];
        this.ProfessionOptions = rows.hasOwnProperty("ProfessionTableOptions")
          ? rows.ProfessionTableOptions
          : [];
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");
      this.social = {};
      this.rows = {};
      this.StartupLoadingFlag = true;
      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/show";
      var upload = {
        UserInterface: 1,
        MemberId: this.MemberId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          thisIns.StartupLoadingFlag = false;

          // thisIns.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records[0];
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.rows = records;
            console.log(thisIns.rows);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          this.StartupLoadingFlag = false;
          // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          this.toast("error", output);
        });
    },
    openRecordPrompt() {
      console.log("openRecordPrompt called");
      this.addRecordPrompt = true;
    },
    hideAddRecordPrompt(flag) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.getTableRecords();
      }
    },
  },
  mounted() {},
  beforeMount() {
    console.log("beforeMount called");
    console.log(this.$route.query.id);
    // this.MemberId = this.$route.query.id;
    var MemberId = this.$session.get("MemberId");
    MemberId = MemberId == (null || undefined) ? 0 : MemberId;
    console.log("my-profile beforeMount called");

    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    if (token == 0) {
      this.$router.push("/login");
    } else {
      this.UserFlag = true;
      this.MemberId = MemberId;
      this.refreshPageData();
    }
  },
};
</script>

<style lang="scss">
#social-media {
  #social-icon {
    font-size: 26px;
  }
}
</style>
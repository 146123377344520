<template>
  <!--begin::Card-->
  <div id="personal-details" class="card card-custom">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title> Personal Information </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-card-text>
          <!--begin::Form-->
          <v-form ref="form1" v-model="valid2" lazy-validation>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h3 class="card-label font-weight-bolder text-dark">
                  Personal Information
                </h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1"
                  >Update your personal informaiton</span
                >
              </template>
              <div class="row">
                <label class="col-xl-3"></label>
                <div class="col-lg-9 col-xl-6">
                  <h5 class="font-weight-bold mb-6">Personal details</h5>
                </div>
              </div>
              <!-- <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">
            Profile Picture
          </label>
          <div class="col-lg-9 col-xl-6">
            <div class="image-input image-input-outline" id="kt_profile_avatar">
              <div
                class="image-input-wrapper"
                :style="{ backgroundImage: `url(${current_photo})` }"
              ></div>
              <label
                class="
                  btn
                  btn-xs
                  btn-icon
                  btn-circle
                  btn-white
                  btn-hover-text-primary
                  btn-shadow
                "
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
              >
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input
                  type="file"
                  ref="ProfilePicture"
                  name="profile_avatar"
                  accept=".png, .jpg, .jpeg"
                  @change="onFileChange($event)"
                />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span
                class="
                  btn
                  btn-xs
                  btn-icon
                  btn-circle
                  btn-white
                  btn-hover-text-primary
                  btn-shadow
                "
                data-action="cancel"
                data-toggle="tooltip"
                title="Cancel avatar"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
              <span
                class="
                  btn
                  btn-xs
                  btn-icon
                  btn-circle
                  btn-white
                  btn-hover-text-primary
                  btn-shadow
                "
                data-action="remove"
                data-toggle="tooltip"
                title="Remove avatar"
                @click="current_photo = null"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>
            <span class="form-text text-muted"
              >Allowed file types: png, jpg, jpeg.</span
            >
          </div>
        </div> -->
              <v-dialog v-model="addRecordPrompt" persistent max-width="80%">
                <upload-profile-picture
                  pageTitle="Change Profile Picture"
                  pageDescription=""
                  :addRecordPrompt="addRecordPrompt"
                  :currentPhoto="current_photo"
                  @hideAddRecordPrompt="changeProfilePhoto"
                  v-if="addRecordPrompt"
                ></upload-profile-picture>
              </v-dialog>
              <v-row wrap>
                <v-col align="start" justify="center" cols="12" md="3">
                  <label class="col-form-label">Profile Picture</label>
                </v-col>
                <v-col cols="12" md="9">
                  <v-img
                    :src="member.MemberImagePath"
                    :alt="member.MemberName"
                    lazy-src="/no-image-available.png"
                    max-width="100"
                    max-height="100"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <input
                    type="hidden"
                    ref="ProfilePicture"
                    name="profile_avatar"
                    accept=".png, .jpg, .jpeg"
                    @change="onFileChange($event)"
                  />
                  <v-btn
                    fab
                    dark
                    small
                    color="indigo"
                    @click.prevent="addRecord"
                  >
                    <v-icon>mdi-upload</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="start" justify="center" cols="12" md="3">
                  <label class="col-form-label">First Name</label>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    v-model="member.FirstName"
                    :disabled="true"
                    val-icon-success="done"
                    hint="Firstname"
                    background-color="#F3F6F9"
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    validate-on-blur
                    rounded
                    solo
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="start" justify="center" cols="12" md="3">
                  <label class="col-form-label">Middle Name</label>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    v-model="member.MiddleName"
                    :disabled="true"
                    val-icon-success="done"
                    hint="Middle Name"
                    background-color="#F3F6F9"
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    validate-on-blur
                    rounded
                    solo
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="start" justify="center" cols="12" md="3">
                  <label class="col-form-label">Last Name</label>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    v-model="member.LastName"
                    :disabled="true"
                    val-icon-success="done"
                    hint="Last Name"
                    background-color="#F3F6F9"
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    validate-on-blur
                    rounded
                    solo
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="start" justify="center" cols="12" md="3">
                  <label class="col-form-label">Membership Id</label>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    :disabled="true"
                    v-model="member.MembershipId"
                    val-icon-success="done"
                    hint="Membership Id"
                    background-color="#F3F6F9"
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    validate-on-blur
                    rounded
                    solo
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <!--
          <v-row wrap>
          <v-col align="start" justify="center" cols="12" md="3">
            <label class="col-form-label">Mobile No</label>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              v-model="member.PrimaryMobile"
              :rules="MobileNoRules"
              :counter="10"
              :disabled="false"
              v-mask="'##########'"
              val-icon-success="done"
              hint="Mobile Number"
              background-color="#F3F6F9"
              type="number"
              class="form-control form-control-lg form-control-solid"
              validate-on-blur
              rounded
              solo
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col align="start" justify="center" cols="12" md="3">
            <label class="col-form-label">Email Id</label>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              v-model="member.EmailId"
              :rules="EmailIdRules"
              val-icon-success="done"
              hint="Email Id"
              type="text"
              background-color="#F3F6F9"
              class="form-control form-control-lg form-control-solid"
              validate-on-blur
              rounded
              solo
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        -->
              <v-row wrap>
                <v-col align="start" justify="center" cols="12" md="3">
                  <label class="col-form-label">DOB</label>
                </v-col>
                <v-col cols="12" md="9">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                    required
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="member.DOB"
                        label="Date of Birth"
                        readonly
                        v-on="on"
                        background-color="#F3F6F9"
                        class="form-control form-control-lg form-control-solid"
                        validate-on-blur
                        rounded
                        solo
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="member.DOB"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="start" justify="center" cols="12" md="3">
                  <label class="col-form-label">Gender</label>
                </v-col>
                <v-col cols="12" md="9">
                  <v-select
                    :reduce="(option) => option.value"
                    :items="GenderOptions"
                    :rules="GenderRules"
                    v-model="member.Gender"
                    required
                    outlined
                    dense
                  ></v-select>
                  <!-- <v-radio-group v-model="member.Gender" row dense>
              <v-radio label="Male" value="1"></v-radio>
              <v-radio label="Female" value="2"></v-radio>
              <v-radio label="Others" value="3"></v-radio>
            </v-radio-group> -->
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="start" justify="center" cols="12" md="3">
                  <label class="col-form-label">Martial Status</label>
                </v-col>
                <v-col cols="12" md="9">
                  <v-select
                    :reduce="(option) => option.value"
                    :items="MartialStatusOptions"
                    :rules="MartialStatusRules"
                    v-model="member.MartialStatus"
                    required
                    outlined
                    dense
                  ></v-select>
                  <!-- <v-radio-group v-model="member.MartialStatus" row dense>
              <v-radio label="Married" value="1"></v-radio>
              <v-radio label="Unmarried" value="2"></v-radio>
            </v-radio-group> -->
                </v-col>
              </v-row>
              <v-row wrap v-if="member.MartialStatus == 1">
                <v-col align="start" justify="center" cols="12" md="3">
                  <label class="col-form-label">Anniversary Date</label>
                </v-col>
                <v-col cols="12" md="9">
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                    required
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="member.AnniversaryDate"
                        label="Anniversary Date"
                        readonly
                        v-on="on"
                        background-color="#F3F6F9"
                        class="form-control form-control-lg form-control-solid"
                        validate-on-blur
                        rounded
                        solo
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="member.AnniversaryDate"
                      @input="menu3 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="start" justify="center" cols="12" md="3">
                  <label class="col-form-label">Blood Group</label>
                </v-col>
                <v-col cols="12" md="9">
                  <v-select
                    :reduce="(option) => option.value"
                    :items="BloodGroupIdOptions"
                    :rules="BloodGroupIdRules"
                    v-model="member.BloodGroupId"
                    required
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="start" justify="center" cols="12" md="3">
                  <label class="col-form-label"
                    >Are you interested to donate blood?</label
                  >
                </v-col>
                <v-col cols="12" md="9">
                  <!-- <v-select
              :reduce="(option) => option.value"
              :items="DonateBloodOptions"
              :rules="DonateBloodRules"
              v-model="member.DonateBlood"
              required
              outlined
              dense
            ></v-select> -->
                  <v-switch
                    inset
                    v-model="member.DonateBlood"
                    :label="`${member.DonateBlood ? 'Yes' : 'No'}`"
                    :color="`${member.DonateBlood == 1 ? 'green' : 'red'}`"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="start" justify="center" cols="12" md="3">
                  <label class="col-form-label">JCI Career</label>
                </v-col>
                <v-col cols="12" md="9">
                  <v-textarea
                    v-model="member.AboutMe"
                    rows="2"
                    value=""
                    required
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
              <!-- <template v-slot:footer>
                <div class="card-toolbar text-right">
                  <v-btn
                    type="submit"
                    @click.prevent="submitForm"
                    elevation="30"
                    :loading="SubmitFlag"
                    shaped
                    tile
                    color="#4dff4d"
                    class="btn btn-success mr-2"
                  >
                    <v-icon left>mdi-content-save-outline</v-icon>
                    Save
                  </v-btn>
                  <v-btn
                    @click="closePrompt"
                    elevation="30"
                    shaped
                    tile
                    mediam
                    color="#a52a2a"
                    class="font-size-h6 px-5 mr-2 white--text"
                    >Close</v-btn
                  >
                </div>
              </template> -->
            </b-card>
            <!--end::Body-->
          </v-form>
          <!--end::Form-->
        </v-card-text>
      </v-sheet>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          @click.prevent="submitForm"
          elevation="30"
          :loading="SubmitFlag"
          shaped
          tile
          color="#4dff4d"
          class="btn btn-success mr-2"
        >
          <v-icon left>mdi-content-save-outline</v-icon>
          Save
        </v-btn>
        <v-btn
          @click="closePrompt"
          elevation="30"
          shaped
          tile
          mediam
          color="#a52a2a"
          class="font-size-h6 px-5 mr-2 white--text"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import UploadProfilePicture from "@/view/pages/profile/my-profile/UploadProfilePicture2.vue";

export default {
  name: "PersonalInformation",
  mixins: [common],
  components: {
    UploadProfilePicture,
  },
  props: {
    member: {
      required: true,
    },
  },
  data() {
    return {
      valid2: true,
      SubmitFlag: false,
      Resultflag: false,

      default_photo: "media/users/blank.png",
      current_photo: null,

      GenderRules: [(v) => !!v || "Gender is required"],
      GenderOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Male" },
        { value: 2, text: "Female" },
        { value: 3, text: "Others" },
      ],

      MartialStatusRules: [(v) => !!v || "Martial Status is required"],
      MartialStatusOptions: [
        { value: "", text: "--Select--" },
        { value: 2, text: "Unmarried" },
        { value: 1, text: "Married" },
        { value: 3, text: "Single" },
        { value: 4, text: "Dont wish to disclose" },
      ],

      FirstNameRules: [(v) => !!v || "First Name is required"],
      MiddleNameRules: [(v) => !!v || "Middle Name is required"],
      LastNameRules: [(v) => !!v || "Last Name is required"],
      MobileNoRules: [(v) => !!v || "Mobile No is required"],
      EmailIdRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      DobRules: [],
      menu2: false,

      AnniversaryDateRules: [],
      menu3: false,

      addRecordPrompt: false,

      BloodGroupIdRules: [(v) => !!v || "Blood Group is required"],
      BloodGroupId: "",
      BloodGroupIdOptions: [],
      BloodGroupIdOptionsLoading: false,

      DonateBlood: "",
      DonateBloodRules: [(v) => !!v || "Donate Blood is required"],
      DonateBloodOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Yes" },
        { value: 0, text: "No" },
      ],
    };
  },
  watch: {
    member: function () {
      console.log("watch member");
      this.current_photo = this.member.MemberImagePath;
      this.default_photo = this.member.DefaultPhoto;
    },
    BloodGroupIdOptions: function () {
      console.log("watch BloodGroupIdOptions");
      this.LoadingFlag = false;
      this.BloodGroupIdOptionsLoading = false;
    },
  },
  mounted() {
    // this.current_photo = this.member.MemberImagePath;
    // this.default_photo = this.member.DefaultPhoto;
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.BloodGroupIdOptionsLoading = true;
      this.getBloodGroupIdOptions("BloodGroupId", "BloodGroupIdOptions");
    },
    addRecord() {
      console.log("addRecord called");
      /*
      var url = "/lom/create";
      var params = {};
      this.pageRedirect(params, url);
      */
      this.addRecordPrompt = true;
    },
    changeProfilePhoto(photo) {
      console.log("changeProfilePhoto called");
      this.addRecordPrompt = false;
      console.log("photo=" + JSON.stringify(photo));
      // this.$refs.ProfilePicture.files[0] = photo;
      if (photo != "") {
        this.member.MemberImagePath = photo;
      }
    },
    getBloodGroupIdOptions(source, destination) {
      console.log("getBloodGroupIdOptions called");
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/sub-category/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          CategoryMainId: 2,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    save() {
      /*
      var name = this.$refs.name.value;
      var surname = this.$refs.surname.value;
      var company_name = this.$refs.company_name.value;
      var phone = this.$refs.phone.value;
      var email = this.$refs.email.value;
      var company_site = this.$refs.company_site.value;
      var photo = this.photo;
      */

      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch(UPDATE_PERSONAL_INFO, {
          name,
          surname,
          company_name,
          phone,
          email,
          company_site,
          photo,
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    cancel() {
      /*
      this.$refs.name.value = this.currentUserPersonalInfo.name;
      this.$refs.surname.value = this.currentUserPersonalInfo.surname;
      this.$refs.company_name.value = this.currentUserPersonalInfo.company_name;
      this.$refs.phone.value = this.currentUserPersonalInfo.phone;
      this.$refs.email.value = this.currentUserPersonalInfo.email;
      this.$refs.company_site.value = this.currentUserPersonalInfo.company_site;
      this.current_photo = this.currentUserPersonalInfo.photo;
      */
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var member = this.member;
      var n1 =
        typeof member === "object" && member !== null
          ? Object.keys(member).length
          : 0;
      console.log("n1=" + n1 + ", member=" + JSON.stringify(member));

      // this.rows = [];

      if (validate1 && n1 > 0) {
        this.SubmitFlag = true;
        this.Resultflag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/personal-update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );
        /*
        var DonateBlood= member.DonateBlood == true ? 1 : 0;
        var AnniversaryDate= member.AnniversaryDate == null ? null : member.AnniversaryDate;
        let upload = new FormData();
        upload.append("MemberId", member.MemberId);
        upload.append("FirstName", member.FirstName);
        upload.append("MiddleName", member.MiddleName);
        upload.append("LastName", member.LastName);
        upload.append("MembershipId", member.MembershipId);
        upload.append("MobileNo", member.PrimaryMobile);
        upload.append("EmailId", member.EmailId);
        upload.append("Dob", member.DOB);
        upload.append("Gender", member.Gender);
        upload.append("MartialStatus", member.MartialStatus);
        upload.append("AnniversaryDate", AnniversaryDate);
        upload.append("BloodGroup", member.BloodGroupId);
        upload.append("DonateBlood", DonateBlood);
        */
        var upload = {
          UserInterface: 1,
          MemberId: member.MemberId,
          FirstName: member.FirstName,
          MiddleName: member.MiddleName,
          LastName: member.LastName,
          MembershipId: member.MembershipId,
          // MobileNo: member.MobileNo,
          // EmailId: member.EmailId,
          Dob: member.DOB,
          Gender: member.Gender,
          MartialStatus: member.MartialStatus,
          AnniversaryDate: member.AnniversaryDate,
          BloodGroup: member.BloodGroupId,
          DonateBlood: member.DonateBlood,
          AboutMe: member.AboutMe,
        };
        /*
        var ProfilePicture = this.$refs.ProfilePicture.files[0];
        if (ProfilePicture) {
          upload.append("ProfilePicture", ProfilePicture);
        }
        var upload = {
          member: member,
        };
        */
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            // "Content-Type": "multipart/form-data",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.Resultflag = true;
              thisIns.toast("success", output);
              thisIns.closePrompt();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1 || !validate2) {
          message += "Kindly fill the required fields";
        }
        if (n1 == 0) {
          message += "Atleast one member is required to transfer";
        }
        this.toast("error", message);
      }
    },
    closePrompt() {
      console.log("closePrompt called");
      this.addRecordPrompt = false;
      this.UserFlag = false;
      this.$emit("hideAddRecordPrompt", this.Resultflag);
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
</style>
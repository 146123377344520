<template>
  <div id="education-details">
    <v-card id="v-card" v-if="StartupLoadingFlag">
      <v-container>
        <v-row wrap>
          <v-col cols="12" xl="6" md="6" sm="6" v-for="n in 4" :key="n">
            <br />
            <br />
            <v-skeleton-loader
              v-bind="attrs"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card id="v-card" v-if="!StartupLoadingFlag">
      <v-app-bar
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          <i id="app-bar-icon" class="fas fa-user-graduate"> </i>&nbsp;&nbsp;
          <span id="app-bar-heading">Education Details</span></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text style="padding-left: 0%; padding-right: 0%">
        <div class="text-center">
          <v-btn
            v-if="tableOptions1.CreateButtonFlag"
            @click.prevent="addRecordPrompt = true"
            medium
            target="_blank"
            color="#0f9d58"
            class="btn btn-primary font-size-h6 px-5 py-4 my-3 white--text"
          >
            Add
          </v-btn>
        </div>
        <!-- <v-row wrap>
              <v-col align="center" cols="12" xl="12" lg="12" md="12" sm="12">
                <v-btn
                  v-if="tableOptions1.RefreshButtonFlag"
                  @click.prevent="refreshPageData"
                  :loading="SearchFlag"
                  small
                  target="_blank"
                  color="#4387f4"
                  class="btn btn-primary font-size-h6 px-5 py-4 my-3 mr-3 white--text"
                >
                  Refresh
                </v-btn>
              </v-col>
            </v-row>
            <v-container class="py-0" v-if="tableData1.length == 0">
              <h4 class="text-danger" align="center" cols="12" md="12">
                No records found.
              </h4>
            </v-container> -->

        <v-container class="py-0" v-if="tableData1.length > 0">
          <!-- <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h5 class="text-primary">
                {{ tableData1.length }} records found.
              </h5>
            </v-col>
          </v-row> -->
          <v-data-iterator
            :items="tableData1"
            :items-per-page="-1"
            hide-default-footer
          >
            <template v-slot:header> </template>
            <template v-slot:default="props">
              <v-row>
                <v-col
                  cols="12"
                  xl="3"
                  md="6"
                  sm="6"
                  v-for="item in props.items"
                  :key="item.EducationId"
                >
                  <v-card raised hover elevation="25">
                    <v-card-title>
                      <v-app-bar
                        height="35%"
                        color="#BF360C"
                        dense
                        dark
                        elevate-on-scroll
                        scroll-target="#scrolling-techniques-7"
                      >
                        <v-toolbar-title>
                          <span style="font-size: medium">
                            {{ item.QualificationTxt }}
                          </span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer> </v-app-bar
                    ></v-card-title>
                    <v-card-text>
                      <v-row wrap>
                        <v-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          class="text-left"
                        >
                          <!-- <h6>Year
                        <span class="text-dark" style="margin-left: 4%;">: {{ item.YearTxt }}</span></h6>
                        <h6>Degree
                        <span class="text-dark" style="margin-left: 35%;">: {{ item.FullDegreeTxt }}</span></h6>
                        <h6>Institution
                        <span class="text-dark" style="margin-left: 12%;">: {{ item.Institution }}</span></h6> -->
                          <h6>
                            Year
                            <span class="text-dark"> : {{ item.YearTxt }}</span>
                          </h6>
                          <h6>
                            Degree
                            <span class="text-dark">
                              : {{ item.FullDegreeTxt }}</span
                            >
                          </h6>
                          <h6>
                            Institution
                            <span class="text-dark">
                              : {{ item.Institution }}</span
                            >
                          </h6>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="item.TransactionTypeId == 2"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span style="font-size: medium">NA</span>
                      </v-btn>
                      <v-btn
                        v-if="
                          item.ReceiptPath != '' && item.TransactionTypeId == 1
                        "
                        :href="item.ReceiptPath"
                        target="_blank"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark color="#2fb65a"> mdi-download </v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="closePrompt"
          elevation="30"
          shaped
          tile
          medium
          color="#a52a2a"
          class="font-size-h6 px-10 mr-3 white--text"
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="addRecordPrompt" persistent width="auto">
      <add-education-details
        pageDescription="Add education details"
        :addRecordPrompt="addRecordPrompt"
        @hideAddRecordPrompt="changeAddRecordPrompt"
        v-if="addRecordPrompt"
      ></add-education-details>
    </v-dialog>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import Swal from "sweetalert2";

import AddEducationDetails from "@/view/pages/profile/my-profile/AddEducationDetails";

export default {
  mixins: [common],
  components: {
    AddEducationDetails,
  },

  data() {
    return {
      StartupLoadingFlag: false,
      MemberId: 0,

      pageTitle: "Education details",
      pageDescription: "List of education details",

      DeleteLoadingFlag: false,

      SearchFlag: false,
      ResultFlag: false,

      selected: [],
      selectedData: {},
      search: "",

      FloatingButton: {},

      tableColumns1: [],
      tableOptions1: {
        FloatingButton: {},
      },
      tableData1: [],

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    refreshPageData(flag) {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    changeAddRecordPrompt(flag) {
      console.log("changeAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.getTableRecords();
      }
    },
    getTableRecords() {
      console.log("getTableRecords is called");

      // var validate1 = this.$refs.form2.validate();
      var validate1 = true;
      console.log("validate1=" + validate1);

      if (validate1) {
        this.SearchFlag = true;
        this.ResultFlag = false;
        this.tableData1 = [];
        this.selected = [];
        this.selectedData = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members-education-details/show";
        var upload = {
          UserInterface: 1,
          MemberId: this.MemberId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;
            thisIns.ResultFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.tableColumns1 = records.TableHeader;
            thisIns.tableOptions1 = records.TableOptions;

            if (flag == 1) {
              // thisIns.toast("success", output);
              thisIns.tableData1 = records.TableData;
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message, false);
      }
    },
    deleteDataAlert(tr, event) {
      console.log("deleteDataAlert called");
      // event.prevent.default();
      // this.selected = tr;
      // var n1 = tr.length;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.confirmAlert();
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      //   var validate1 = this.$refs.form1.validate();
      //   console.log("validate1=" + validate1);
      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "Year <b> " + tr.YearTxt + "</b>";
        htmlTxt += "<br/> Qualification <b> " + tr.QualificationTxt + "</b>";
        htmlTxt += "<br/> Degree <b> " + tr.FullDegreeTxt + "</b>";
        htmlTxt += "<br/> Institution <b> " + tr.Institution + "</b>";
        htmlTxt += "<br/><br/>Do you want to delete the data?";
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete the data?",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    deleteData() {
      console.log("deleteData is called");

      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.DeleteLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members-education-details/destroy";
        var upload = {
          UserInterface: 1,
          Member: tr.MemberId,
          Education: tr.EducationId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            // thisIns.SearchFlag = false;
            // thisIns.ResultFlag = true;
            thisIns.DeleteLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.selectedData = {};
              thisIns.selected = [];
              thisIns.getTableRecords();
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.DeleteLoadingFlag = false;
          });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt");
    },
  },
  mounted() {},
  beforeMount() {
    console.log("beforeMount called");
    console.log(this.$route.query.id);
    // this.MemberId = this.$route.query.id;
    var MemberId = this.$session.get("MemberId");
    MemberId = MemberId == (null || undefined) ? 0 : MemberId;
    console.log("my-profile beforeMount called");

    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    if (token == 0) {
      this.$router.push("/login");
    } else {
      this.UserFlag = true;
      this.MemberId = MemberId;

      var RoleId = this.$session.get("RoleId");
      RoleId = RoleId == (null || undefined) ? 0 : RoleId;

      console.log("token=" + token + ", RoleId=" + RoleId);
      this.RoleId = RoleId;
      if (RoleId != "") {
        this.refreshPageData();
        this.tableColumns1.push(
          { text: "Payment Type", value: "TransactionTypeTxt" },
          { text: "Date", value: "PaidDate" },
          { text: "Register No", value: "RegisterNo" },
          { text: "Receipt No", value: "ReceiptNo" },
          { text: "Title", value: "TitleName" },
          { text: "Receipt", value: "ReceiptPath" }
        );
        console.log(this.tableColumns1);
      }

      this.refreshPageData();
    }
  },
};
</script>


<style lang="scss">
#education-details {
  .v-tab {
    font-size: 14px;
    // color: black;
  }
  .v-app-bar {
    background-color: #0097d7;
  }
  #app-bar-icon {
    margin-left: 0%;
    color: #edbe38;
    font-size: 24px;
  }
  #app-bar-heading {
    color: white;
    font-size: 18px;
    font-weight: bold;
    font-family: "Roboto Serif";
  }
}
</style>
<template>
  <div id="add-profession">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>Edit Family details</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="800"
      >
        <v-container style="overflow: hidden">
          <v-form ref="form1" v-model="valid1" lazy-validation>
            <!-- v-on:submit.prevent="submitForm" -->
            <v-card-title> </v-card-title>
            <v-card-text>
              <h6 class="text-danger">* indicates required field</h6>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" md="4">
                    <h6><span class="text-danger">*</span> Relation</h6>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="RelationTypeOptionsLoading"
                      :items="RelationTypeOptions"
                      :rules="RelationTypeRules"
                      v-model="RelationType"
                      required
                      outlined
                      dense
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <h6><span class="text-danger">*</span> Name</h6>
                    <v-text-field
                      v-model="Name"
                      :rules="NameRules"
                      placeholder="Name"
                      hint="Enter name"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <h6>DOB</h6>
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          required
                          v-model="Dob"
                          placeholder="DOB"
                          readonly
                          v-on="on"
                          background-color="#F3F6F9"
                          class="form-control form-control-lg form-control-solid"
                          validate-on-blur
                          solo
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="Dob"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <h6>Membership Id <small>(Optional)</small></h6>
                    <v-text-field
                      v-model="MembershipId"
                      :rules="MembershipIdRules"
                      placeholder="Membership Id"
                      hint="Enter Membership Id"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <h6><span class="text-danger">*</span> Mobile Number</h6>
                    <v-text-field
                      v-model="MobileNo"
                      :rules="MobileNoRules"
                      type="number"
                      :counter="10"
                      placeholder="Mobile Number"
                      hint="Enter Mobile Number"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <h6><span class="text-danger">*</span> Pincode</h6>
                    <v-text-field
                      v-model="Pincode"
                      :rules="PincodeRules"
                      placeholder="Pincode"
                      hint="Enter Pincode"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <h6><span class="text-danger">*</span> State</h6>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="StateIdOptionsLoading"
                      :items="StateIdOptions"
                      :rules="StateIdRules"
                      v-model="StateId"
                      required
                      outlined
                      dense
                      clearable
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <h6><span class="text-danger">*</span> District</h6>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="DistrictIdOptionsLoading"
                      :items="DistrictIdOptions"
                      :rules="DistrictIdRules"
                      v-model="DistrictId"
                      required
                      outlined
                      dense
                      clearable
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <h6><span class="text-danger">*</span> City</h6>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="CityIdOptionsLoading"
                      :items="CityIdOptions"
                      :rules="CityIdRules"
                      v-model="CityId"
                      required
                      outlined
                      clearable
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <h6><span class="text-danger">*</span> Address</h6>
                    <v-textarea
                      v-model="Address"
                      :rules="AddressRules"
                      placeholder="Address"
                      hint="Enter Address"
                      required
                      outlined
                      dense
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <v-btn
                      :disabled="!valid1"
                      @click.prevent="confirmAlert"
                      :loading="SubmitFlag"
                      elevation="30"
                      shaped
                      tile
                      medium
                      color="#1db954"
                      class="font-size-h6 px-4 mr-3 white--text"
                    >
                      Save
                    </v-btn>
                    <v-btn
                      @click="closePrompt"
                      elevation="30"
                      shaped
                      tile
                      medium
                      color="#a52a2a"
                      class="font-size-h6 px-4 mr-3 white--text"
                    >
                      Close
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-container>
      </v-sheet>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions> -->
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    editRecordPrompt: {
      type: Boolean,
      required: true,
    },
    FamilyId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      UploadFlag: false,

      RelationTypeRules: [(v) => !!v || "Relation Type is required"],
      RelationType: "",
      RelationTypeOptions: [],
      RelationTypeOptionsLoading: false,

      menu2: false,
      Dob: "",
      DobRules: [(v) => !!v || "Date is required"],

      Name: "",
      NameRules: [(v) => !!v || "Name is required"],

      MembershipId: "",
      MembershipIdRules: [],

      MobileNo: "",
      MobileNoRules: [(v) => !!v || "Mobile Number is required"],

      Pincode: "",
      PincodeRules: [(v) => !!v || "Pincode is required"],

      Address: "",
      AddressRules: [(v) => !!v || "Address is required"],

      StateIdRules: [(v) => !!v || "State is required"],
      StateId: "",
      StateIdOptions: [],
      StateIdOptionsLoading: false,

      DistrictIdRules: [(v) => !!v || "District is required"],
      DistrictId: "",
      DistrictIdOptions: [],
      DistrictIdOptionsLoading: false,

      CityIdRules: [(v) => !!v || "City is required"],
      CityId: "",
      CityIdOptions: [],
      CityIdOptionsLoading: false,
    };
  },
  computed: {},
  watch: {
    RelationTypeOptions: function () {
      console.log("watch RelationTypeOptions");
      this.LoadingFlag = false;
      this.RelationTypeOptionsLoading = false;
    },
    StateIdOptions: function () {
      console.log("watch StateIdOptions");
      this.LoadingFlag = false;
      this.StateIdOptionsLoading = false;
    },
    StateId: function () {
      console.log("watch StateId");
      this.LoadingFlag = true;
      this.DistrictIdOptionsLoading = true;
      this.getDistrictIdOptions(
        "DistrictId",
        "DistrictIdOptions",
        this.StateId
      );
    },
    DistrictIdOptions: function () {
      console.log("watch DistrictIdOptions");
      this.LoadingFlag = false;
      this.DistrictIdOptionsLoading = false;
    },
    DistrictId: function () {
      console.log("watch DistrictId");
      this.LoadingFlag = true;
      this.CityIdOptionsLoading = true;
      this.getCityIdOptions("CityId", "CityIdOptions", this.DistrictId);
    },
    CityIdOptions: function () {
      console.log("watch CityIdOptions");
      this.LoadingFlag = false;
      this.CityIdOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      this.closePrompt();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideEditRecordPrompt", this.ResultFlag);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var FamilyId = this.FamilyId;
      console.log({ FamilyId });
      this.getRelationTypeOptions();
      this.getStateIdOptions("StateId", "StateIdOptions");
      this.getTableRecords();
    },
    getRelationTypeOptions() {
      console.log("getRelationTypeOptions called");
      this.RelationTypeOptionsLoading = true;
      var selectbox1_source = "RelationType";
      var selectbox1_destination = "RelationTypeOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        CategoryMainId: 7,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getStateIdOptions(source, destination) {
      console.log("getStateIdOptions called");
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/states/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getDistrictIdOptions(source, destination, StateId) {
      console.log("getDistrictIdOptions called StateId=" + StateId);
      if (source != "" && destination != "" && StateId != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityIdOptions(source, destination, DistrictId) {
      console.log("getCityIdOptions called DistrictId=" + DistrictId);
      if (source != "" && destination != "" && DistrictId != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getTableRecords() {
      console.log("getTableRecords called");

      // var validate1 = this.$refs.form2.validate();
      var FamilyId = this.FamilyId;
      console.log({ FamilyId });

      if (FamilyId > 0) {
        this.SearchFlag = true;
        this.StartupLoadingFlag = true;
        this.ResultFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members-family-details/member-show";
        var upload = {
          UserInterface: 1,
          FamilyId: this.FamilyId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var success = "";
        var error = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;
            thisIns.StartupLoadingFlag = false;
            thisIns.ResultFlag = true;

            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            success = response.data.success;
            error = response.data.error;
            console.log("success=" + success + ", error=" + error);

            if (flag == 1) {
              thisIns.RelationType = records.RelationshipId;
              thisIns.Name = records.RelativeName;
              thisIns.Dob = records.RelativeDOB;
              thisIns.MembershipId = records.RelativeMembershipId;
              thisIns.MobileNo = records.RelativeMobileNo;
              thisIns.Pincode = records.RelativePincode;
              thisIns.StateId = records.RelativeStateId;
              thisIns.DistrictId = records.RelativeDistrictId;
              thisIns.CityId = records.RelativeCityId;
              thisIns.Address = records.RelativeAddress;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
            thisIns.StartupLoadingFlag = false;
          });
      } else {
        var message = "Family Details not found";
        this.sweetAlert("error", message, false);
      }
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      // this.rows = [];

      if (validate1) {
        this.SubmitFlag = true;
        this.ResultFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members-family-details/member-update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var upload = {
          UserInterface: 1,
          FamilyId: this.FamilyId,
          RelationType: this.RelationType,
          Name: this.Name,
          DateOfBirth: this.Dob,
          MembershipId: this.MembershipId,
          MobileNo: this.MobileNo,
          Pincode: this.Pincode,
          City: this.CityId,
          Address: this.Address,
        };
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.sweetAlert("success", output, true);
              thisIns.resetForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#add-profession {
}
</style>
<template>
  <div id="add-profession">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          {{ pageTitle }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-container style="overflow: hidden">
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="confirmAlert"
          >
            <!-- v-on:submit.prevent="submitForm" -->
            <v-card-title> </v-card-title>
            <v-card-text>
              <h6 class="text-danger">* indicates required field</h6>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" md="3">
                    <label class="text-danger">* Year</label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="YearIdOptionsLoading"
                      :items="YearIdOptions"
                      :rules="YearIdRules"
                      v-model="YearId"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label class="text-danger">* Education Level</label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="QualificationIdOptionsLoading"
                      :items="QualificationIdOptions"
                      :rules="QualificationIdRules"
                      v-model="QualificationId"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label class="text-danger">* Degree</label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="DegreeIdOptionsLoading"
                      :items="DegreeIdOptions"
                      :rules="DegreeIdRules"
                      v-model="DegreeId"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label class="text-danger">* Specialization</label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="SpecilizationIdOptionsLoading"
                      :items="SpecilizationIdOptions"
                      :rules="SpecilizationIdRules"
                      v-model="SpecilizationId"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col cols="12" sm="12" md="12">
                    <label>Institution</label>
                    <v-text-field
                      v-model="Institution"
                      :rules="InstitutionRules"
                      placeholder="Institution"
                      hint="Enter Institution"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <!-- <v-btn
                      @click="closePrompt"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#a52a2a"
                      class="font-size-h6 px-10 mr-3 white--text"
                    >
                      Close
                    </v-btn> -->
                    <v-btn
                      :disabled="!valid1"
                      @click.prevent="confirmAlert"
                      :loading="SubmitFlag"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#1db954"
                      class="font-size-h6 px-10 mr-3 white--text"
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-container>
      </v-sheet>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    addRecordPrompt: {
      type: Boolean,
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      pageTitle: "Add Education details",
      pageDescription: "Add education details",

      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      UploadFlag: false,

      YearIdRules: [(v) => !!v || "Year is required"],
      YearId: "",
      YearIdOptions: [],
      YearIdOptionsLoading: false,

      QualificationIdRules: [(v) => !!v || "Qualification is required"],
      QualificationId: "",
      QualificationIdOptions: [],
      QualificationIdOptionsLoading: false,

      DegreeIdRules: [(v) => !!v || "Degree is required"],
      DegreeId: "",
      DegreeIdOptions: [],
      DegreeIdOptionsLoading: false,

      SpecilizationIdRules: [(v) => !!v || "Specilization is required"],
      SpecilizationId: "",
      SpecilizationIdOptions: [],
      SpecilizationIdOptionsLoading: false,

      Institution: "",
      InstitutionRules: [],
    };
  },
  computed: {},
  watch: {
    YearIdOptions: function () {
      console.log("watch YearIdOptions");
      this.LoadingFlag = false;
      this.YearIdOptionsLoading = false;
    },
    QualificationIdOptions: function () {
      console.log("watch QualificationIdOptions");
      this.LoadingFlag = false;
      this.QualificationIdOptionsLoading = false;
    },
    DegreeIdOptions: function () {
      console.log("watch DegreeIdOptions");
      this.LoadingFlag = false;
      this.DegreeIdOptionsLoading = false;
    },
    SpecilizationIdOptions: function () {
      console.log("watch SpecilizationIdOptions");
      this.LoadingFlag = false;
      this.SpecilizationIdOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.ResultFlag = true;
      this.$refs.form1.reset();
      this.closePrompt();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      // this.$emit('Member', this.NewMember);
      this.$emit("hideAddRecordPrompt", this.ResultFlag);
    },
    refreshPageData() {
      console.log("refreshPageData called");

      this.YearIdOptionsLoading = true;
      this.getCategoryOptions("YearId", "YearIdOptions", {
        UserInterface: 1,
        CategoryMainId: 9,
        OrderBy: "category_sub_name desc",
      });

      this.QualificationIdOptionsLoading = true;
      this.getCategoryOptions("QualificationId", "QualificationIdOptions", {
        UserInterface: 1,
        CategoryMainId: 10,
      });

      this.DegreeIdOptionsLoading = true;
      this.getCategoryOptions("DegreeId", "DegreeIdOptions", {
        UserInterface: 1,
        CategoryMainId: 11,
      });

      this.SpecilizationIdOptionsLoading = true;
      this.getCategoryOptions("SpecilizationId", "SpecilizationIdOptions", {
        UserInterface: 1,
        CategoryMainId: 12,
      });
    },
    getCategoryOptions(source, destination, condition) {
      console.log("getCategoryOptions called");
      console.log("source=" + source + ", destination=" + destination);
      var n1 = Object.keys(condition).length;
      console.log("n1=" + n1 + ", condition=" + JSON.stringify(condition));
      if (source != "" && destination != "" && n1 > 0) {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/sub-category/options";
        var selectbox1_conditions_array = condition;
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      // this.rows = [];

      if (validate1) {
        this.SubmitFlag = true;
        this.ResultFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members-education-details/store";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var upload = {
          UserInterface: 1,
          Year: this.YearId,
          Qualification: this.QualificationId,
          Degree: this.DegreeId,
          Specilization: this.SpecilizationId,
          Institution: this.Institution,
        };
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.resetForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#add-profession {
}
</style>
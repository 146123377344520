<template>
  <div id="address-information">
    <v-card id="v-card" v-if="StartupLoadingFlag">
      <v-container>
        <v-row wrap>
          <v-col cols="12" xl="6" md="6" sm="6" v-for="n in 4" :key="n">
            <br />
            <br />
            <v-skeleton-loader
              v-bind="attrs"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      elevation="4"
      outlined
      shaped
      v-if="!StartupLoadingFlag"
      id="v-card"
    >
      <br />

      <v-row wrap>
        <v-col cols="12">
          <h3 class="text-dark text-center">
            <b id="heading">Member Details</b>
          </h3>
        </v-col>
      </v-row>

      <v-card-text style="padding-left: 3%; padding-right: 0%" id="card-text">
        <v-row wrap>
          <v-col cols="9" lg="9">
            <h4>
              <i id="app-bar-icon" class="fas fa-money-bill-wave"></i> &nbsp;
              Member Subscription Details
            </h4>
          </v-col>
          <v-col cols="2" lg="2">
            <v-btn
              id="view-button"
              variant="text"
              small
              @click.prevent="openRecordPrompt2"
              >view</v-btn
            >
          </v-col>
        </v-row>

        <v-row wrap>
          <v-col cols="9" lg="9">
            <h4>
              <i id="app-bar-icon" class="fas fa-sitemap"></i> &nbsp; JCI
              Designation Details
            </h4>
          </v-col>
          <v-col cols="2" lg="2">
            <v-btn
              id="view-button"
              variant="text"
              small
              @click.prevent="openRecordPrompt3"
              >view</v-btn
            >
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col cols="9" lg="9">
            <h4>
              <i id="app-bar-icon" class="fas fa-sitemap"></i>
              &nbsp; Member Educational Details
            </h4>
          </v-col>
          <v-col cols="2" lg="2">
            <v-btn
              id="view-button"
              variant="text"
              small
              @click.prevent="openRecordPrompt4"
              >view</v-btn
            >
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col cols="9" lg="9">
            <h4>
              <i id="app-bar-icon" class="fas fa-user-secret"></i> &nbsp; Member
              Privacy Settings
            </h4>
          </v-col>
          <v-col cols="2" lg="2">
            <v-btn
              id="view-button"
              variant="text"
              small
              @click.prevent="openRecordPrompt5"
              >view</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="addRecordPrompt2" persistent max-width="80%">
      <SubscriptionDetails
        v-if="UserFlag && addRecordPrompt2"
        @hideAddRecordPrompt="hideAddRecordPrompt2"
      >
      </SubscriptionDetails>
    </v-dialog>

    <v-dialog v-model="addRecordPrompt3" persistent max-width="80%">
      <MemberDesignation
        v-if="UserFlag && addRecordPrompt3"
        @hideAddRecordPrompt="hideAddRecordPrompt3"
      >
      </MemberDesignation>
    </v-dialog>

    <v-dialog v-model="addRecordPrompt4" persistent max-width="80%">
      <EducationDetails
        v-if="UserFlag && addRecordPrompt4"
        @hideAddRecordPrompt="hideAddRecordPrompt4"
      >
      </EducationDetails>
    </v-dialog>

    <v-dialog v-model="addRecordPrompt5" persistent max-width="80%">
      <MemberPrivacyPolicy
        v-if="UserFlag && addRecordPrompt5"
        :MemberId="MemberId"
        @hideAddRecordPrompt="hideAddRecordPrompt5"
      >
      </MemberPrivacyPolicy>
    </v-dialog>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import Swal from "sweetalert2";

import SubscriptionDetails from "@/view/pages/profile/profile-cards-1/SubscriptionDetails";
import MemberDesignation from "@/view/pages/profile/profile-cards-1/MemberDesignation";
import EducationDetails from "@/view/pages/profile/profile-cards-1/EducationDetails";
import SocialMedia from "@/view/pages/profile/profile-cards-1/SocialMedia";
import MemberPrivacyPolicy from "@/view/pages/profile/profile-cards-1/MemberPrivacyPolicy";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {
    SubscriptionDetails,
    MemberDesignation,
    EducationDetails,
    SocialMedia,
    MemberPrivacyPolicy,
  },

  data() {
    return {
      StartupLoadingFlag: false,

      StartupLoadingFlag: false,
      MemberId: 0,
      rows: {},
      UserFlag: false,

      addRecordPrompt2: false,
      addRecordPrompt3: false,
      addRecordPrompt4: false,
      addRecordPrompt5: false,
    };
  },
  computed: {},
  watch: {
    rows: function () {
      console.log("watch rows");
      var rows = this.rows;
      var n1 =
        typeof rows === "object" && rows !== null
          ? Object.keys(rows).length
          : 0;
      console.log("n1=" + n1 + ", rows=" + JSON.stringify(rows));
      if (n1 > 0) {
        this.ResultFlag = 1;
        this.address = rows.hasOwnProperty("Address") ? rows.Address[0] : {};
        this.contact = rows.hasOwnProperty("Contact") ? rows.Contact : [];
        this.designation = rows.hasOwnProperty("OfficesHeld")
          ? rows.OfficesHeld
          : [];
        this.dues = rows.hasOwnProperty("Dues") ? rows.Dues : [];
        this.social = rows.hasOwnProperty("Social") ? rows.Social : [];
        this.titles = rows.hasOwnProperty("Titles") ? rows.Titles : [];
        this.ProfessionData = rows.hasOwnProperty("Profession")
          ? rows.Profession
          : [];
        this.ProfessionHeaders = rows.hasOwnProperty("ProfessionTableHeader")
          ? rows.ProfessionTableHeader
          : [];
        this.ProfessionOptions = rows.hasOwnProperty("ProfessionTableOptions")
          ? rows.ProfessionTableOptions
          : [];
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      // this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");
      this.StartupLoadingFlag = true;
      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/show";
      var upload = {
        UserInterface: 1,
        MemberId: this.MemberId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          thisIns.StartupLoadingFlag = false;

          thisIns.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records[0];
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.rows = records;
            console.log(thisIns.rows);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          this.StartupLoadingFlag = false;
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          this.toast("error", output);
        });
    },

    openRecordPrompt2() {
      console.log("openRecordPrompt1 called");
      this.addRecordPrompt2 = true;
    },
    hideAddRecordPrompt2() {
      console.log("hideAddRecordPrompt2 called");
      this.addRecordPrompt2 = false;
    },

    openRecordPrompt3() {
      console.log("openRecordPrompt1 called");
      this.addRecordPrompt3 = true;
    },
    hideAddRecordPrompt3() {
      console.log("hideAddRecordPrompt3 called");
      this.addRecordPrompt3 = false;
    },

    openRecordPrompt4() {
      console.log("openRecordPrompt1 called");
      this.addRecordPrompt4 = true;
    },
    hideAddRecordPrompt4() {
      console.log("hideAddRecordPrompt4 called");
      this.addRecordPrompt4 = false;
    },

    openRecordPrompt5() {
      console.log("openRecordPrompt1 called");
      this.addRecordPrompt5 = true;
    },
    hideAddRecordPrompt5() {
      console.log("hideAddRecordPrompt5 called");
      this.addRecordPrompt5 = false;
    },
  },
  mounted() {},
  beforeMount() {
    console.log("beforeMount called");
    console.log("beforeMount called");
    console.log(this.$route.query.id);
    // this.MemberId = this.$route.query.id;
    var MemberId = this.$session.get("MemberId");
    MemberId = MemberId == (null || undefined) ? 0 : MemberId;
    console.log("my-profile beforeMount called");

    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    if (token == 0) {
      this.$router.push("/login");
    } else {
      this.UserFlag = true;
      this.MemberId = MemberId;
      this.refreshPageData();
    }
  },
};
</script>
<template>
  <div id="add-profession">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ pageDescription }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-container style="overflow: hidden">
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="submitForm"
          >
            <v-card-title> </v-card-title>
            <v-card-text>
              <h6 class="text-danger">* indicates required field</h6>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" md="4">
                    <label class="text-danger">* Occupation</label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="OccupationTypeOptionsLoading"
                      :items="OccupationTypeOptions"
                      :rules="OccupationTypeRules"
                      v-model="OccupationType"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <label class="text-danger">* Industry Type</label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="IndustryTypeOptionsLoading"
                      :items="IndustryTypeOptions"
                      :rules="IndustryTypeRules"
                      v-model="IndustryType"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4">
                    <label class="text-danger">* Company Name</label>
                    <v-text-field
                      v-model="CompanyName"
                      :rules="CompanyNameRules"
                      placeholder="Company name"
                      hint="Enter your company name"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col cols="12" md="12">
                    <label class="text-danger">Line 1</label>
                    <v-text-field
                      v-model="Street1"
                      :rules="Street1Rules"
                      placeholder="Street Line 1"
                      hint="Enter your street line 1"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col cols="12" md="8">
                    <label>Line 2</label>
                    <v-text-field
                      v-model="Street2"
                      :rules="Street2Rules"
                      placeholder="Street Line 2"
                      hint="Enter your street line 2"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <label>Area</label>
                    <v-text-field
                      v-model="Area"
                      :rules="AreaRules"
                      placeholder="Area"
                      hint="Enter your area"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <label class="text-danger">State</label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="StateIdOptionsLoading"
                      :items="StateIdOptions"
                      :rules="StateIdRules"
                      v-model="StateId"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <label class="text-danger">District</label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="DistrictIdOptionsLoading"
                      :items="DistrictIdOptions"
                      :rules="DistrictIdRules"
                      v-model="DistrictId"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <label class="text-danger">City</label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="CityIdOptionsLoading"
                      :items="CityIdOptions"
                      :rules="CityIdRules"
                      v-model="CityId"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col cols="12" md="4">
                    <label>Mobile number</label>
                    <v-text-field
                      v-model="MobileNo"
                      :rules="MobileNoRules"
                      placeholder="Mobile No"
                      type="number"
                      hint="Enter your mobile number"
                      :counter="10"
                      v-mask="'##########'"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <label>Email Id</label>
                    <v-text-field
                      v-model="EmailId"
                      :rules="EmailIdRules"
                      type="email"
                      placeholder="Email Id"
                      hint="Enter your email id"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <label>Website</label>
                    <v-text-field
                      v-model="WebsiteAddress"
                      :rules="WebsiteAddressRules"
                      placeholder="Website"
                      type="url"
                      hint="Enter your website address"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-4">
                      Upload logo here <br />
                      <small>(only jpg, png image files are accepted)</small>
                    </h6>
                    <v-file-input
                      :clearable="false"
                      truncate-length="25"
                      accept="image/png, image/jpeg"
                      placeholder="Upload Chairman Photo"
                      prepend-icon="mdi-account-box"
                      append-outer-icon="mdi-delete"
                      v-model="UploadImage1"
                      @change="addImage(1)"
                    >
                      <template v-slot:append-outer>
                        <v-icon color="red" @click="deleteImage(1)">
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-4">Image Edit Options</h6>
                    <br />
                    <v-btn-toggle>
                      <v-btn fab small @click.prevent="flip(true, false)">
                        <v-icon>mdi-swap-horizontal-bold</v-icon>
                      </v-btn>
                      <v-btn fab small @click.prevent="flip(false, true)">
                        <v-icon>mdi-swap-vertical-bold</v-icon>
                      </v-btn>
                      <v-btn fab small @click.prevent="rotate(90)">
                        <v-icon>mdi-rotate-right-variant</v-icon>
                      </v-btn>
                      <v-btn fab small @click.prevent="rotate(-90)">
                        <v-icon>mdi-rotate-left-variant</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                    <br />
                    <br />
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-4">
                      Uploaded Image will Appear Here
                    </h6>
                    <br />
                    <cropper ref="cropper" :src="SelectedImage.ImageUrl" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!valid1"
                @click.prevent="confirmAlert"
                :loading="SubmitFlag"
                elevation="30"
                shaped
                tile
                small
                color="#1db954"
                class="font-size-h6 px-10 mr-3 white--text"
              >
                Save
              </v-btn>
              <v-btn
                @click="closePrompt"
                elevation="30"
                shaped
                tile
                small
                color="#a52a2a"
                class="font-size-h6 px-10 mr-3 white--text"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { CircleStencil, Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  mixins: [common],
  components: {
    Cropper,
    CircleStencil,
  },
  props: {
    addRecordPrompt: {
      type: Boolean,
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      UploadFlag: false,

      rows: [],
      member: {},

      OccupationTypeRules: [(v) => !!v || "Occupation Type is required"],
      OccupationType: "",
      OccupationTypeOptions: [],
      OccupationTypeOptionsLoading: false,

      IndustryTypeRules: [(v) => !!v || "Industry Type is required"],
      IndustryType: "",
      IndustryTypeOptions: [],
      IndustryTypeOptionsLoading: false,

      CompanyName: "",
      CompanyNameRules: [(v) => !!v || "Company name is required"],

      MiddleName: "",
      MiddleNameRules: [],

      LastName: "",
      LastNameRules: [],

      Street1: "",
      Street1Rules: [(v) => !!v || "Street 1 is required"],

      Street2: "",
      Street2Rules: [],

      WebsiteAddress: "",
      WebsiteAddressRules: [],

      Area: "",
      AreaRules: [],

      MobileNo: "",
      MobileNoRules: [
        // (v) => !!v || "Mobile No is required"
      ],

      EmailId: "",
      EmailIdRules: [
        // (v) => !!v || "E-mail is required",
        // (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      StateIdRules: [(v) => !!v || "State is required"],
      StateId: "",
      StateIdOptions: [],
      StateIdOptionsLoading: false,

      DistrictIdRules: [(v) => !!v || "District is required"],
      DistrictId: "",
      DistrictIdOptions: [],
      DistrictIdOptionsLoading: false,

      CityIdRules: [(v) => !!v || "City is required"],
      CityId: "",
      CityIdOptions: [],
      CityIdOptionsLoading: false,

      UploadImage1: {},
      SelectedImage: {},
      image: {
        // src: "media/images/jci-logo.png",
        src: "",
        type: null,
      },
    };
  },
  computed: {},
  watch: {
    StateIdOptions: function () {
      console.log("watch StateIdOptions");
      this.LoadingFlag = false;
      this.StateIdOptionsLoading = false;
    },
    StateId: function () {
      console.log("watch StateId");
      this.LoadingFlag = true;
      this.DistrictIdOptionsLoading = true;
      this.getDistrictIdOptions(
        "DistrictId",
        "DistrictIdOptions",
        this.StateId
      );
    },
    DistrictIdOptions: function () {
      console.log("watch DistrictIdOptions");
      this.LoadingFlag = false;
      this.DistrictIdOptionsLoading = false;
    },
    DistrictId: function () {
      console.log("watch DistrictId");
      this.LoadingFlag = true;
      this.CityIdOptionsLoading = true;
      this.getCityIdOptions("CityId", "CityIdOptions", this.DistrictId);
    },
    CityIdOptions: function () {
      console.log("watch CityIdOptions");
      this.LoadingFlag = false;
      this.CityIdOptionsLoading = false;
    },
    OccupationTypeOptions: function () {
      console.log("watch OccupationTypeOptions");
      this.LoadingFlag = false;
      this.OccupationTypeOptionsLoading = false;
    },
    IndustryTypeOptions: function () {
      console.log("watch IndustryTypeOptions");
      this.LoadingFlag = false;
      this.IndustryTypeOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      this.closePrompt();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      // this.$emit('Member', this.NewMember);
      this.$emit("hideAddRecordPrompt", this.ResultFlag);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.StateIdOptionsLoading = true;
      this.getStateIdOptions("StateId", "StateIdOptions");
      this.getOccupationTypeOptions();
      this.getIndustryTypeOptions();
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.UploadImage1;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.SelectedImage.src) {
            URL.revokeObjectURL(this.SelectedImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.SelectedImage = {
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.SelectedImage = {
            ImageType: null,
            ImageUrl: null,
          };
          this.UploadImage1 = {};
          break;

        default:
          break;
      }
    },
    getOccupationTypeOptions() {
      console.log("getOccupationTypeOptions called");
      this.OccupationTypeOptionsLoading = true;
      var selectbox1_source = "OccupationType";
      var selectbox1_destination = "OccupationTypeOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        CategoryMainId: 5,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getIndustryTypeOptions() {
      console.log("getIndustryTypeOptions called");
      this.IndustryTypeOptionsLoading = true;
      var selectbox1_source = "IndustryType";
      var selectbox1_destination = "IndustryTypeOptions";
      var selectbox1_url = "api/jcom/business-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    flip(x, y) {
      console.log("flip x=" + x + ", y=" + y);
      if (this.$refs.cropper.customImageTransforms.rotate % 180 !== 0) {
        this.$refs.cropper.flip(!x, !y);
      } else {
        this.$refs.cropper.flip(x, y);
      }
    },
    rotate(angle) {
      console.log("rotate=" + angle);
      this.$refs.cropper.rotate(angle);
    },
    getStateIdOptions(source, destination) {
      console.log("getStateIdOptions called");
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/states/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getDistrictIdOptions(source, destination, StateId) {
      console.log("getDistrictIdOptions called StateId=" + StateId);
      if (source != "" && destination != "" && StateId != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityIdOptions(source, destination, DistrictId) {
      console.log("getCityIdOptions called DistrictId=" + DistrictId);
      if (source != "" && destination != "" && DistrictId != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    confirmAlert() {
      var message = "";
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.uploadImage();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        if (validate1 == false) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
        // this.toast("error", message, true);
      }
    },
    uploadImage() {
      console.log("uploadImage called");
      var message = "";
      var validate1 = this.$refs.form1.validate();
      // var validate1 = false;
      console.log("validate1=" + validate1);
      var UploadedImageName = this.UploadImage1.name;
      UploadedImageName =
        UploadedImageName == (null || undefined) ? "" : UploadedImageName;
      console.log("UploadedImageName=" + UploadedImageName);
      if (validate1) {
        this.UploadFlag = true;
        const formData = new FormData();
        formData.append("OccupationType", this.OccupationType);
        formData.append("IndustryType", this.IndustryType);
        formData.append("CompanyName", this.CompanyName);
        formData.append("Street1", this.Street1);
        formData.append("Street2", this.Street2);
        formData.append("Area", this.Area);
        formData.append("StateId", this.StateId);
        formData.append("DistrictId", this.DistrictId);
        formData.append("CityId", this.CityId);
        formData.append("MobileNo", this.MobileNo);
        formData.append("EmailId", this.EmailId);
        formData.append("WebsiteAddress", this.WebsiteAddress);
        const { canvas } = this.$refs.cropper.getResult();
        if (canvas && UploadedImageName != "") {
          var image = "";
          canvas.toBlob((blob) => {
            formData.append("ProfilePicture", blob, UploadedImageName);
            this.submitForm(formData);
          }, "image/jpeg");
        } else {
          this.submitForm(formData);
        }
      } else {
        if (validate1 == false) {
          message += "Kindly fill the required fields. ";
        }
        // this.toast("error", "Kindly fill the required fields", true);
        this.sweetAlert("error", message, false);
      }
    },
    submitForm(upload) {
      console.log("submitForm is called");

      var UploadFlag = this.UploadFlag;
      console.log("UploadFlag=" + UploadFlag);

      if (UploadFlag) {
        this.SubmitFlag = true;
        this.ResultFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members-profession/store";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.UploadFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.ResultFlag = true;
              thisIns.resetForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.UploadFlag = false;
          });
      } else {
        var message = "File is not selected";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
    this.member = {};
  },
};
</script>
<style lang="scss">
#add-profession {
}
</style>
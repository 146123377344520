<template>
  <div id="add-family-nominee">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>Add Family Nominee details</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-container style="overflow: hidden">
        <v-form ref="form1" v-model="valid1" lazy-validation>
          <!-- <v-card-title> </v-card-title> -->
          <v-card-text>
            <h6 class="text-center">
              <v-btn color="blue" medium @click.prevent="addRecordPrompt = true">
                <span style="color:white ;">Add Family Member</span>
              </v-btn>
              <!-- <v-icon color="blue" @click.prevent="addRecordPrompt = true"
                >mdi-plus-circle</v-icon
              > -->
            </h6>
            <v-container>
              <v-row wrap>
                <v-col cols="12" sm="6" md="4">
                  <h6>
                    <span class="text-danger">*</span> Select Nominee Person
                  </h6>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="NomineeOptionsLoading"
                    :items="NomineeOptions"
                    :rules="NomineeRules"
                    v-model="Nominee"
                    required
                    outlined
                    dense
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="confirmAlert"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                  >
                    Save
                  </v-btn>
                  <v-btn
                    @click="closePrompt"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#a52a2a"
                    class="font-size-h6 px-10 mr-3 white--text"
                  >
                    Close
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="addRecordPrompt" persistent width="70%">
      <add-family-details
        pageDescription="Add family details"
        :addRecordPrompt="addRecordPrompt"
        @hideAddRecordPrompt="changeAddRecordPrompt"
        v-if="addRecordPrompt"
      ></add-family-details>
    </v-dialog>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import Swal from "sweetalert2";

import AddFamilyDetails from "@/view/pages/profile/my-profile/AddFamilyDetails";

export default {
  mixins: [common],
  components: {
    AddFamilyDetails,
  },
  props: {
    MemberId: {
      required: true,
    },
    FamilyId: {
      required: true,
    },
  },

  data() {
    return {
      valid1: true,

      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      UploadFlag: false,

      NomineeRules: [(v) => !!v || "Nominee is required"],
      Nominee: "",
      NomineeOptions: [],
      NomineeOptionsLoading: false,

      addRecordPrompt: false,
    };
  },
  computed: {},
  watch: {
    NomineeOptions: function () {
      console.log("watch NomineeOptions");
      this.LoadingFlag = false;
      this.NomineeOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      console.log("MemberId = ", this.MemberId);
      this.getNomineeOptions();
      this.Nominee = this.FamilyId;
    },

    changeAddRecordPrompt(flag) {
      console.log("changeAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log(flag);
      if (flag) {
        this.getNomineeOptions();
      }
    },

    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt", this.ResultFlag);
    },

    getNomineeOptions() {
      console.log("getNomineeOptions called");
      this.NomineeOptionsLoading = true;
      var selectbox1_source = "Nominee";
      var selectbox1_destination = "NomineeOptions";
      var selectbox1_url = "api/members-family-details/nominee-options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        MemberId: this.MemberId,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },

    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.SubmitFlag = true;
        this.ResultFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members-family-details/nominee-update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var upload = {
          UserInterface: 1,
          MemberId: this.MemberId,
          Nominee: this.Nominee,
        };
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.closePrompt();
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },

  mounted() {},
  beforeMount() {
    console.log("beforeMount called");
    this.refreshPageData();
  },
};
</script>